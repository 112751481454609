import axios from 'axios'

import BaseService from './BaseService.js'

class PremiumService extends BaseService {
  constructor() {
    super({
      baseUrl: import.meta.env.VITE_PREMIUM_API_URL,
    })
  }

  fetchZuoraAccount() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendZuoraAccountCreation({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  sendZuoraAccountUpdate({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  fetchStudioPlusCampaign({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchAvailablePaymentMethods(currencyCode) {
    return axios({
      url: `${this.baseUrl}/api/zuora/payment-methods?currencyCode=${currencyCode}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchExistingPaymentMethods() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/payment-methods`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  validateTaxId(payload) {
    return axios({
      url: `${this.baseUrl}/api/validation/tax`,
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusCampaignsForOrganisation() {
    return axios({
      url: `${this.baseUrl}/api/premium/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchTransactionsHistory(id) {
    return axios({
      url: `${this.baseUrl}/api/zuora/subscription/${id}/invoices`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getInvoice(id) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/invoices/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusPrice({ accommodationId, countryCode, postalCode, countryState, vatId }) {
    let requestUrl = `/api/premium/${accommodationId}/price`
    if (!postalCode || !countryCode) {
      requestUrl = `/api/premium/${accommodationId}/price`
    } else if (countryState !== null && (vatId === null || vatId === '')) {
      requestUrl = `/api/premium/${accommodationId}/price?countryCode=${countryCode}&postalCode=${postalCode}&state=${countryState}`
    } else if (countryState !== null && vatId !== null && vatId.length > 0) {
      requestUrl = `/api/premium/${accommodationId}/price?countryCode=${countryCode}&postalCode=${postalCode}&state=${countryState}&vatId=${vatId}`
    } else if (countryState === null && vatId !== null && vatId?.length > 0) {
      requestUrl = `/api/premium/${accommodationId}/price?countryCode=${countryCode}&postalCode=${postalCode}&vatId=${vatId}`
    } else if (countryState === null && (vatId === null || vatId === '')) {
      requestUrl = `/api/premium/${accommodationId}/price?countryCode=${countryCode}&postalCode=${postalCode}`
    }

    return axios({
      url: `${this.baseUrl}${requestUrl}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendStudioPlusSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  cancelSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/deactivate`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getPaidSubscriptions(accommodationId) {
    return axios({
      url: `${this.baseUrl}/api/premium/admin/${accommodationId}/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new PremiumService()
