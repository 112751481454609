<template>
  <h3 class="tw-font-bold tw-mb-1">{{ $t('studio_plus_prop_overview_checkout') }}</h3>
  <BaseCard noPadding class="tw-p-5">
    <h3 class="tw-font-bold tw-mb-1">{{ $t('app_hotel_details_hotel_name_form') }}</h3>
    <div class="tw-flex tw-items-center tw-mb-5">
      <AccommodationImage
        :image="currentAccommodation.mainImageUrls"
        class="tw-min-h-full tw-h-[32px] tw-w-[32px] tw-shrink-0 tw-mr-4"
      />
      <div class="tw-flex tw-items-start tw-flex-col tw-justify-start tw-grow tw-truncate">
        <p class="tw-font-bold tw-truncate tw-w-full">{{ currentAccommodation.name }}</p>
        <p class="tw-text-xxs tw-w-full tw-truncate tw-text-gray-700">
          <strong>ID:</strong> <span>{{ currentAccommodation.accommodationId }}</span>
        </p>
      </div>
    </div>

    <h3 class="tw-font-bold tw-mb-1 tw-flex tw-items-center" v-if="directRatesPartnerId">
      {{ $t('studio_plus_connectivity_provider') }}
      <UiTooltip tooltip-position="top" :label="$t('studio_plus_connectivity_tooltip')">
        <BaseIcon
          :height="16"
          :width="16"
          icon-name="information-outline"
          class="tw-fill-none tw-stroke-gray-900 tw-h-[20px] tw-w-[20px] tw-ml-1"
        >
          <InformationOutline />
        </BaseIcon>
      </UiTooltip>
    </h3>

    <div v-if="directRatesPartnerId" class="tw-flex tw-h-[32px]">
      <img
        :src="`https://imgcy.trivago.com/e_trim/f_auto,dpr_2,bo_1px_solid_transparent,c_pad,h_30,q_auto,w_80/partnerlogos-s3/${directRatesPartnerId}`"
        alt="partner logo"
        height="30"
        class="partner-logo"
      />
    </div>
  </BaseCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import AccommodationImage from '@/components/dashboard/accommodation/AccommodationImage.vue'

import useRateConnect from '@/components/rateConnect/queries'
import { useAccommodationsByOrgId } from '@/layouts/queries'

const props = defineProps({
  accommodationId: String,
})
const store = useStore()

const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === props.accommodationId)
)
const { hotelierData } = useRateConnect(props.accommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)
</script>
