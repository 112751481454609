<template>
  <h1
    class="tw-text-gray-900 tw-font-bold"
    :class="
      isChangingCampaign ? 'tw-text-center tw-mb-4 tw-text-xl md:tw-text-left' : 'tw-text-center tw-mb-8 tw-text-2xl'
    "
  >
    {{ $t('rc_setup_campagn_header') }}
  </h1>
  <p v-if="isChangingCampaign" class="tw-mb-8 tw-text-center md:tw-text-left">
    {{ $t('rc_campaign_type_options_text') }}
  </p>

  <div class="scrollable-container tw-flex sm:tw-hidden">
    <div class="scrollable-content" id="cpa-item" v-if="eligibleTypeOfCPACampaign !== null">
      <CampaignTypeSelectCardNew
        :campaignType="eligibleTypeOfCPACampaign"
        class="tw-pl-2 tw-mx-auto"
        isRecommended
        :isSelected="selectedCampaignType === eligibleTypeOfCPACampaign"
        :isCurrentlyActive="currentlyActiveCampaignType === eligibleTypeOfCPACampaign"
      />
    </div>
  </div>
  <div aria-hidden="true" class="tw-flex tw-gap-4 tw-justify-center sm:tw-hidden tw-mt-4">
    <div
      v-if="eligibleTypeOfCPACampaign !== null"
      class="tw-h-3 tw-w-3 tw-rounded-full tw-bg-blue-800"
      :class="selectedCampaignType === eligibleTypeOfCPACampaign ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
    ></div>
    <div
      class="tw-h-3 tw-w-3 tw-rounded-full tw-bg-blue-800"
      :class="selectedCampaignType === 'cpc' ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
    ></div>
  </div>
  <div class="tw-hidden sm:tw-grid sm:tw-gap-4 sm:tw-justify-center sm:tw-grid-flow-col">
    <button @click="onCampaignTypeCardClick(eligibleTypeOfCPACampaign)" v-if="eligibleTypeOfCPACampaign !== null">
      <CampaignTypeSelectCardNew
        :campaignType="eligibleTypeOfCPACampaign"
        :isSelected="selectedCampaignType === eligibleTypeOfCPACampaign"
        :isCurrentlyActive="currentlyActiveCampaignType === eligibleTypeOfCPACampaign"
      />
    </button>
  </div>
  <p class="tw-text-center tw-text-gray-700 tw-mt-8">{{ $t('rc_campaign_footnote') }}</p>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import CampaignTypeSelectCardNew from '@/components/rateConnect/CampaignTypeSelectCardNew.vue'

const props = defineProps({
  currentlyActiveCampaignType: {
    type: String,
    required: false,
    default: null,
    validator(value) {
      return ['netCpa', 'cpa', 'cpc'].includes(value)
    },
  },
  eligibleTypeOfCPACampaign: {
    type: String,
    default: 'netCpa',
    validator(value) {
      return ['netCpa', 'cpa', null].includes(value)
    },
  },
  selectedCampaignType: {
    type: String,
    default: 'netCpa',
    validator(value) {
      return ['netCpa', 'cpa', 'cpc'].includes(value)
    },
  },
  isChangingCampaign: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onSelectedCampaignTypeChange'])
const observer = ref(null)
const cpcElementMobile = ref(null)

const onCampaignTypeCardClick = type => {
  emit('onSelectedCampaignTypeChange', type)
}

const setIntersectionObserverForMobile = () => {
  const carouselContainer = window.document.querySelector('.scrollable-container')
  const cards = window.document.querySelectorAll('.scrollable-content')
  const IntersectionObserverOptions = { threshold: 0.5, root: carouselContainer }
  observer.value = new IntersectionObserver(function (entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const campaignType = entry.target.id === 'cpc-item' ? 'cpc' : props.eligibleTypeOfCPACampaign
        emit('onSelectedCampaignTypeChange', campaignType)
      }
    })
  }, IntersectionObserverOptions)
  cards.forEach(card => observer.value.observe(card))
}

onMounted(() => {
  if (window.innerWidth < 500) {
    if (props.selectedCampaignType === 'cpc')
      cpcElementMobile.value.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'instant' })
    setIntersectionObserverForMobile()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  }
})

onUnmounted(() => {
  observer.value?.disconnect()
})
</script>

<style scoped>
.scrollable-container {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 1.5rem;
}
.scrollable-content {
  scroll-snap-align: start;
  width: 95%;
  flex: none;
}
</style>
