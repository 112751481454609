import axios from 'axios'

import BaseService from './BaseService.js'
import PremiumService from '@/services/PremiumService.js'

const accommodationNewApiBaseUrl = `${import.meta.env.VITE_NEW_ACCOMMODATIONS_API_URL}`
const translationsBaseUrl = `${import.meta.env.VITE_HOTEL_SEARCH_GRAPHQL_API_URL}`
const paymentApiGraphQl = `${import.meta.env.VITE_GATEWAY}/graphql`

const getFacilitiesTranslationsQuery = `
  query GetConceptDetails($input: ConceptDetailsInput!) {
    getConceptDetails(input: $input) {
      conceptDetails {
        nsid {
          ns
          id
        }
        translatedName {
          value
        }
      }
    }
  }
`

const accommodationsByOrganisationQuery = `
 query getAccommodationsByOrganisationId($organisationId: UUID!){
      accommodationsList(organizationId: $organisationId){
       accommodationTypeId
        propertyId
        streetAddress
        starRating
        checkInHour
        checkOutHour
        city
        countryId
        email
        fax
        homepageUrl
        geoFlag
        chainId
        languageTag
        latitude
        longitude
        mainImageUrls
        name
        phone
        postalCode
        accommodationId
      }
    }
  `

const getHotelChainsQuery = `
  query GetConceptsByNamespace($input: ConceptsByNamespaceInput!) {
    getConceptsByNamespace(input: $input) {
      namespace
      concepts {
        nsid {
          id
          ns
        }
        translatedName {
          value
        }
      }
    }
  }
`

class AccommodationService extends BaseService {
  constructor() {
    super({ baseUrl: accommodationNewApiBaseUrl })
  }

  newSearch({ searchTerm }) {
    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/search/${searchTerm}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getAccommodationsByListOfIds({ accommodationIds = [], organisationId }) {
    if (!this.studioToken) throw new Error('Error in POST /api/v1/accommodations: No studioToken provided')
    if (accommodationIds.length === 0)
      throw new Error('Error in GET /api/v1/accommodations: no list of accommodations provided')
    if (!organisationId) throw new Error('Error in GET /api/v1/accommodations: no organisationId provided')
    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${organisationId}/get`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      maxBodyLength: Infinity,
      data: {
        ids: accommodationIds,
      },
    })
  }

  async getAccommodationsWithStudioSubscription(organisationId) {
    return await axios({
      url: `${paymentApiGraphQl}`,
      method: 'POST',
      data: {
        query: accommodationsByOrganisationQuery,
        variables: { organisationId },
      },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(response => {
      return Promise.all(
        response.data.data.accommodationsList.map(async item => {
          const studioPlusSubscription = await PremiumService.fetchStudioPlusCampaign({
            accommodationId: item.accommodationId,
          })

          return {
            accommodationName: item.name,
            accommodationId: item.accommodationId,
            isStudioPlus: studioPlusSubscription.data !== '',
            studioPlusSubscription: studioPlusSubscription.data,
          }
        })
      )
    })
  }

  fetchNewAccommodationBasicInfo(accommodationId) {
    if (!this.studioToken)
      throw new Error('Error in GET /api/v1/accommodations/{accommodationId}: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  async fetchListTranslations(languageCode, namespace) {
    const response = await axios({
      url: `${translationsBaseUrl}`,
      method: 'POST',
      data: {
        query: getHotelChainsQuery,
        variables: {
          input: {
            namespaces: namespace,
          },
        },
      },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'x-trv-language': languageCode,
        'Apollographql-Client-Name': 'trivago-business-studio-property-details',
        'Apollographql-Client-Version': '1.0.0',
      },
    })

    response.data = response.data.data.getConceptsByNamespace
    return response
  }

  updatePropertyDetails({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updatePropertyFeatures(accommodationId, groupId, body) {
    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}/features/by-group/${groupId}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchLanguagesFields({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/api/v1/translations/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  deleteLanguage({ accommodationId, language }) {
    return axios({
      url: `${this.baseUrl}/api/v1/translations/${accommodationId}/${language}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateLanguage({ accommodationId, language, body }) {
    return axios({
      url: `${this.baseUrl}/api/v1/translations/${accommodationId}/${language}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  addNewLanguage({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/v1/translations/${accommodationId}`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getMainImage(accommodationId) {
    return axios({
      url: `${this.baseUrl}/api/v1/images/${accommodationId}/main-images`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  async setNewMainImage({ accommodationId, imageId }) {
    return await axios({
      url: `${this.baseUrl}/api/v1/images/${accommodationId}/set-main-image`,
      method: 'POST',
      data: {
        imageId,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  async uploadImage({ accommodationId, body }) {
    return await axios({
      url: `${this.baseUrl}/api/v1/images/${accommodationId}/upload`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  deleteImage({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/v1/images/${accommodationId}/images`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  async fetchImages(accommodationId) {
    return await axios({
      url: `${this.baseUrl}/api/v1/images/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const result = response.data.images.map(item => {
        return { isMainImage: false, ...item }
      })
      let images = []
      await this.getMainImage(accommodationId)
        .then(mainImageData => {
          if (mainImageData.data) {
            images = [
              {
                ...mainImageData.data,
                active: true,
                isMainImage: true,
                imageId: `mainImage-${mainImageData.data.imageId}`,
              },
              ...result,
            ]
          }
        })
        .catch(() => {
          images = result
        })
      return images
    })
  }

  async fetchFields(fieldGroupId, locale) {
    return await axios({
      url: `${this.baseUrl}/api/v1/features/by-group/${fieldGroupId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const featuresIds = []
      response.data.map(item => featuresIds.push(item.featureId))
      const featuresNames = await this.fetchFacilitiesTranslations(locale, featuresIds)
      const newResponse = response.data.map(item => {
        let translatedName = featuresNames.data.find(featuresName => featuresName.nsid.id === item.featureId)
        return { ...item, name: translatedName?.translatedName?.value }
      })

      return newResponse
    })
  }

  async fetchAccommodationFields(accommodationId, locale) {
    return await axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}/features/by-group`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const featuresIds = []
      response.data.map(item => {
        item.features.forEach(feature => featuresIds.push(feature.featureId))
      })
      const featuresNames = await this.fetchFacilitiesTranslations(locale, featuresIds)
      const newResponse = response.data.map(item => {
        const newFields = item.features.map(feature => {
          let translatedName = featuresNames.data.find(featuresName => featuresName.nsid.id === feature.featureId)
          return { ...feature, name: translatedName?.translatedName?.value }
        })
        return {
          ...item,
          features: newFields,
        }
      })
      return newResponse
    })
  }

  async fetchFacilitiesTranslations(locale, featuresIds) {
    const input = featuresIds?.map(item => {
      return { ns: 300, id: item }
    })

    const response = await axios({
      url: `${translationsBaseUrl}`,
      method: 'POST',
      data: {
        query: getFacilitiesTranslationsQuery,
        variables: {
          input: { nsids: input },
        },
      },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'x-trv-language': locale,
        'Apollographql-Client-Name': 'trivago-business-studio-property-details',
        'Apollographql-Client-Version': '1.0.0',
      },
    })

    response.data = response.data.data.getConceptDetails.conceptDetails
    return response
  }

  fetchTimeFramesTrends({ accommodationId, startDate }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/type}: No studioToken provided'
      )
    if (!accommodationId)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/type}: No accommodationId provided'
      )
    if (!startDate)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/type}: No timeFrame provided'
      )

    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}/trend/traveler/type?startDate=${startDate}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchVisitorsProfileDuration({ accommodationId, startDate }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/stay}: No studioToken provided'
      )
    if (!accommodationId)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/stay}: No accommodationId provided'
      )
    if (!startDate)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/stay}: No timeFrame provided'
      )

    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}/trend/traveler/stay?startDate=${startDate}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchVisitorsProfileCountries({ accommodationId, startDate }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/locale}: No studioToken provided'
      )
    if (!accommodationId)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/locale}: No accommodationId provided'
      )
    if (!startDate)
      throw new Error(
        'Error in GET /api/v1/accommodations/{accommodationId}/trend/traveler/locale}: No timeFrame provided'
      )

    return axios({
      url: `${this.baseUrl}/api/v1/accommodations/${accommodationId}/trend/traveler/locale?startDate=${startDate}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new AccommodationService()
