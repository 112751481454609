<template>
  <BaseCard class="tw-my-4">
    <div class="tw-p-6">
      <div
        class="tw-border-b tw-pb-4 tw-border-gray-200 tw-flex tw-justify-between tw-items-start"
        ref="paymentDetails"
      >
        <div>
          <h3 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('paymentMethods.heading') }}</h3>
          <p class="tw-text-sm">{{ $t('paymentMethods.info') }}</p>
        </div>
      </div>
      <PaymentMethodPreview />
      <BillingInformationPreview />
      <MainButton v-if="hasBillingAccount" @click="onPaymentEditClick" class="tw-mt-4">{{
        $t('paymentMethods.edit.cta')
      }}</MainButton>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BillingInformationPreview from '@/components/settings/paymentMethods/BillingInformationPreview.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PaymentMethodPreview from '@/components/settings/paymentMethods/PaymentMethodPreview.vue'
import { useBillingAccountDetails } from '@/components/settings/paymentMethods/queries'

const router = useRouter()
const route = useRoute()
const store = useStore()
const paymentDetails = ref(null)

const queryClient = useQueryClient()
const organisationId = computed(() => store.state?.session?.selectedOrganisation?.id)

const { hasBillingAccount } = useBillingAccountDetails(organisationId)

const onPaymentEditClick = () => {
  router.push({
    name: 'settings-subscriptions-edit-payment',
  })
}

onMounted(() => {
  if (route.query?.editPayment === 'true') {
    queryClient.invalidateQueries({ queryKey: ['paymentDetails'] })
  }
})
</script>
