<template>
  <h4 class="tw-font-bold tw-mt-7 tw-mb-1">{{ $t('paymentMethods.billingAddress.heading') }}</h4>
  <div v-if="isFetching">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-24" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-800" v-html="$t('errors.wrong_data_from_app')" />
  <div v-else>
    <div v-if="hasBillingAccount">
      <div>{{ accountName }}</div>
      <div>{{ billEmail }}</div>
      <div>
        <span>{{ billFirstName }}{{ ' ' }}</span>
        <span>{{ billLastName }}</span>
      </div>
      <div>
        <span v-if="billAddress1">{{ billAddress1 }}, </span>
        <span v-if="billAddress2">{{ billAddress2 }}, </span>
        <span v-if="billCity">{{ billCity }}, </span>
        <span v-if="billState">{{ billState }}, </span>
        <span v-if="billZipCode">{{ billZipCode }}, </span>
        <span v-if="billCountry">{{ billCountry }}</span>
      </div>
      <div v-if="taxVATId">{{ taxVATId }}</div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { useBillingAccountDetails } from '@/components/settings/paymentMethods/queries'

const store = useStore()
const currentOrganisation = computed(() => store.state?.session?.selectedOrganisation?.id)

const { billingAccountDetails, hasBillingAccount, isError, isFetching } = useBillingAccountDetails(currentOrganisation)

const accountName = computed(() => billingAccountDetails?.value?.basicInfo?.name)
const billEmail = computed(() => billingAccountDetails?.value?.billToContact?.workEmail)
const billFirstName = computed(() => billingAccountDetails?.value?.billToContact?.firstName)
const billLastName = computed(() => billingAccountDetails?.value?.billToContact?.lastName)
const billAddress1 = computed(() => billingAccountDetails?.value?.billToContact?.address1)
const billAddress2 = computed(() => billingAccountDetails?.value?.billToContact?.address2)
const billCity = computed(() => billingAccountDetails?.value?.billToContact?.city)
const billState = computed(() => billingAccountDetails?.value?.billToContact?.state)
const billZipCode = computed(() => billingAccountDetails?.value?.billToContact?.zipCode)
const billCountry = computed(() => billingAccountDetails?.value?.billToContact?.country)
const taxVATId = computed(() => billingAccountDetails?.value?.taxInfo?.VATId)
</script>
