<template>
  <div class="tw-max-w-sm tw-text-center tw-relative tw-h-full">
    <div
      class="tw-rounded-xl tw-p-5 tw-shadow-mdLg tw-relative tw-pb-8 tw-h-full tw-border-2"
      :class="{
        'tw-bg-blue-100 tw-border-blue-800': isSelected,
        'tw-border-transparent': !isSelected && !isRecommended,
        'tw-bg-white': !isSelected,
      }"
    >
      <div
        v-if="isRecommended"
        class="tw-absolute tw-top-0 tw-left-0 tw-py-1 tw-px-3 tw-text-xs tw-text-white tw-font-bold tw-bg-blue-800 tw-rounded-tl-xl tw-rounded-br-xl"
      >
        {{ $t('rc_upgrade_ncpa_recommended_campaign') }}
      </div>
      <div class="tw-relative">
        <h5 class="tw-font-bold tw-text-2xl tw-mb-5 tw-mt-5 tw-px-6">{{ $t(campaignNameKey) }}</h5>
      </div>
      <p v-if="campaignType !== 'cpc'">
        {{
          campaignType === 'netCpa'
            ? $t('rc_pay_per_stay_commission_header')
            : $t('rc_pay_per_booking_commission_header')
        }}
      </p>
      <p v-if="campaignType !== 'cpc'" class="tw-font-bold tw-text-2xl">
        {{ campaignType === 'netCpa' ? $t('rc_pay_per_stay_commissions') : $t('rc_pay_per_booking_commissions') }}
      </p>
      <hr v-if="campaignType !== 'cpc'" class="tw-my-5 tw-h-px tw-border-none tw-bg-blue-300" />
      <div class="benefits-list" v-html="$t(campaignBenefitsKey)"></div>
      <small v-if="isCurrentlyActive" class="tw-block tw-text-sm tw-text-left tw-font-bold tw-mt-5 tw-text-blue-800">{{
        $t('rc_campaign_type_active')
      }}</small>
    </div>
    <div v-if="campaignType !== 'cpc'" class="tw-absolute tw-bottom-[-14px] tw-w-full">
      <span
        class="tw-py-1 tw-px-3 tw-bg-green-300 tw-border-4 tw-border-white tw-rounded-[20px] tw-text-green-800 tw-inline-flex tw-items-center tw-gap-1"
      >
        <CheckMark v-if="campaignType === 'netCpa'" height="10px" width="10px" class="tw-fill-green-800" />
        <small v-if="campaignType === 'netCpa'" class="tw-font-bold tw-text-xs tw-mr-1">{{
          $t('rc_upgrade_ncpa_highlight_1')
        }}</small>
        <CheckMark height="10px" width="10px" class="tw-fill-green-800" />
        <small class="tw-font-bold tw-text-xs tw-mr-1">
          {{ $t('rc_upgrade_ncpa_highlight_2') }}
        </small>
        <CheckMark height="10px" width="10px" class="tw-fill-green-800" />
        <small class="tw-font-bold tw-text-xs">
          {{ $t('rc_upgrade_ncpa_highlight_3') }}
        </small>
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CheckMark from '@/components/icons/CheckMark.vue'

const props = defineProps({
  isCurrentlyActive: {
    type: Boolean,
    default: false,
  },
  isRecommended: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  campaignType: {
    type: String,
    default: 'netCpa',
    validator(value) {
      return ['netCpa', 'cpa', 'cpc'].includes(value)
    },
  },
})

const campaignNameKey = computed(() => {
  if (props.campaignType === 'netCpa') return 'rc_pay_per_stay'
  if (props.campaignType === 'cpa') return 'rc_pay_per_booking'
  return 'rc_pay_per_click'
})

const campaignBenefitsKey = computed(() => {
  if (props.campaignType === 'netCpa') return 'rc_pay_per_stay_campaign_benefits'
  if (props.campaignType === 'cpa') return 'rc_pay_per_booking_campaign_benefits'
  return 'rc_pay_per_click_campaign_benefits'
})
</script>

<style scoped>
.benefits-list :deep(ul) {
  @apply tw-text-left tw-flex tw-flex-col tw-gap-1 tw-list-disc tw-px-5;
}
</style>
