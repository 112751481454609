<template>
  <div
    v-if="isModalOpen"
    class="tw-fixed tw-inset-0 tw-top-0 tw-z-40 tw-w-full tw-h-full tw-overflow-hidden tw-flex tw-overflow-y-auto"
    :class="overlay === 'light' ? 'tw-overlay-white-75' : 'tw-overlay-black-60'"
  >
    <div class="modal-dialog">
      <div
        class="tw-bg-white md:tw-rounded-xl base-card tw-overflow-hidden modal-content"
        :class="props.customClasses"
        v-click-outside="onClickAwayFn"
      >
        <div v-if="withHeader" class="modal-header tw-p-6 tw-pb-2 tw-pt-6 tw-border-b tw-border-gray-300">
          <div class="tw-flex tw-w-full tw-pb-4 tw-justify-between">
            <slot name="modalHeader" />
            <div class="tw-justify-end tw-self-center tw-flex">
              <button
                class="tw-outline-none tw-absolute tw-top-[16px] tw-right-[16px]"
                data-cy="close-modal-button"
                @click="toggleModal()"
                @keydown.enter="toggleModal()"
              >
                <BaseIcon icon-name="cross" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
                  <Cross />
                </BaseIcon>
              </button>
            </div>
          </div>
        </div>

        <div
          v-else-if="withCloseBtn"
          class="modal-header tw-relative"
          :class="{ 'tw-p-5 tw-pb-0 tw-pt-4': !noPadding }"
        >
          <button
            class="tw-outline-none tw-absolute tw-right-[16px] tw-top-[16px] tw-z-10"
            @click="toggleModal()"
            @keydown.enter="toggleModal()"
          >
            <BaseIcon icon-name="cross" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
              <Cross />
            </BaseIcon>
          </button>
        </div>
        <div class="modal-body tw-flex tw-grow" :class="{ 'tw-px-6': !noPadding }">
          <slot />
        </div>
        <div
          v-if="withFooter"
          class="modal-footer tw-flex md:tw-p-6 md:tw-pt-6 tw-mt-6 tw-py-2 tw-px-6 tw-items-center tw-space-x-2 tw-rounded-b tw-border-t tw-border-gray-300"
        >
          <slot name="modalFooter" />
        </div>
        <div v-show="showLoading" class="tw-overlay-white-75 tw-absolute tw-h-full tw-w-full">
          <LaunchpadLoader class="tw-h-full tw-flex tw-justify-center tw-items-center" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { watch, onUnmounted, onMounted } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
  customClasses: {
    type: String,
    default: '',
  },
  overlay: {
    type: String,
    default: 'light',
  },
  withFooter: {
    type: Boolean,
    default: false,
  },
  withHeader: {
    type: Boolean,
    default: true,
  },
  showLoading: {
    type: Boolean,
    default: false,
  },
  withCloseBtn: {
    type: Boolean,
    default: true,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggleModal', 'onClickAway'])
const toggleModal = () => emit('toggleModal')
const onClickAwayFn = () => emit('onClickAway')

watch(
  () => props.isModalOpen,
  () => {
    // make the body non scrollable to avoid scrolling when the modal is open
    handleBodyScroll(props.isModalOpen)
  }
)
onMounted(() => {
  if (props.isModalOpen) {
    handleBodyScroll(props.isModalOpen)
  }
})
onUnmounted(() => {
  handleBodyScroll(false)
})

const handleBodyScroll = value => {
  if (value) {
    document.body.classList.add('tw-overflow-hidden')
  } else {
    document.body.classList.remove('tw-overflow-hidden')
  }
}
</script>
<style scoped>
.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  min-height: 580px;

  @media (min-width: theme('screens.md')) {
    max-height: calc(100% - 100px);
    margin: auto;
  }
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-body {
  overflow-y: auto;
}
.base-card {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
