import { computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'

import CurrencyService from '@/services/CurrencyService'
import OrganisationManagementService from '@/services/OrganisationManagementService'

export function useAllCurrencies() {
  const { isLoading, data, isFetching } = useQuery({
    queryKey: ['currencies'],
    queryFn: () => CurrencyService.getCurrencies(),
  })

  const currencies = computed(() => data?.value?.data?.data.currencies)
  return {
    isFetchingAllCurrencies: isFetching,
    isLoading,
    currencies,
  }
}

export function useGetOrganisationCurrency(token) {
  const { isLoading, isFetching, data, fetchStatus } = useQuery({
    queryKey: ['organisationCurrency', token],
    queryFn: async () => {
      const response = await CurrencyService.getUserCurrency()
      if (!response.data?.data?.currency) throw new Error('getUserCurrency returned no currency object')
      return response.data.data.currency
    },
    onError: error => {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'currency-selector-getOrganisationCurrency')
        scope.setExtra('Response error', error)
        Sentry.captureException(error)
      })
    },
  })

  const isOrganisationsCurrencyLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isOrganisationsCurrencyLoading,
    isOrganisationCurrencyFetching: isFetching,
    userCurrency: data,
  }
}

export function useUpdateCurrency() {
  const queryClient = useQueryClient()
  const {
    isLoading: updatingUserCurrencyLoading,
    isSuccess: updatingUserCurrencySuccess,
    isError: updatingUserCurrencyError,
    mutate: updateCurrency,
  } = useMutation({
    mutationFn: ({ userId, currency }) =>
      OrganisationManagementService.updateUserProfile({ userId, body: { currency } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationCurrency'] })
    },
    onError: error => {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'currency-selector-updateCurrency')
        scope.setExtra('Response error', error)
        Sentry.captureException(error)
      })
    },
  })

  return {
    updatingUserCurrencyLoading,
    updatingUserCurrencySuccess,
    updatingUserCurrencyError,
    updateCurrency,
  }
}
