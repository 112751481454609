<template>
  <div>
    <SubscriptionStudioPlusPreview
      v-if="isStudioPlus"
      :subscription="subscription"
      :accommodationId="accommodationId"
      :accommodationName="accommodationName"
      @onViewDetails="onViewDetailsClick"
    />
    <div
      v-else
      class="tw-grid w-grid-cols-[1fr] md:tw-grid-cols-[1fr_1fr] tw-items-end lg:tw-grid-cols-[2fr_1.25fr_1fr_1.5fr_1.5fr_2fr] tw-gap-4 md:tw-gap-6 tw-items-end"
    >
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscribed_property') }}</div>
        <div class="tw-text-sm">{{ accommodationName }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_plan') }}</div>
        <div class="tw-text-sm">{{ productName ? $t(productName) : $t('subscriptions.noAccommodationName') }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_billing') }}</div>
        <p class="tw-text-sm">{{ $t('subscription_billing_monthly') }}</p>
      </div>

      <div
        v-if="isExpiredSubscription && subscription?.status"
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_expiry_date') }}</div>
        <p class="tw-text-sm">{{ subscriptionEndDate }}</p>
      </div>

      <div
        v-if="!isExpiredSubscription && subscription?.status && isActiveSubscription"
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscriptions.nextPayment') }}</div>
        <p class="tw-text-sm">{{ paymentNextActionDate }}</p>
      </div>
      <div
        v-if="!isExpiredSubscription && subscription?.status && !isActiveSubscription"
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscriptions.activeUntil') }}</div>
        <p class="tw-text-sm">{{ paymentNextActionDate }}</p>
      </div>

      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">{{ '' }}</div>
      <div class="tw-flex w-flex-col tw-w-full md:tw-w-auto md:tw-flex-row lg:tw-justify-self-end tw-mt-[-10px]">
        <GhostButton size="medium" class="tw-w-full md:tw-w-auto" @click="onViewDetailsClick">{{
          $t('rc_bookings_list_view')
        }}</GhostButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import GhostButton from '@/components/base/buttons/GhostButton.vue'
import SubscriptionStudioPlusPreview from './SubscriptionStudioPlusPreview.vue'

const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})
const store = useStore()
const router = useRouter()
const isStudioPlus = computed(() => props.subscription?.isStudioPlus)
const productName = computed(() => props.subscription?.product?.details?.[0]?.name)
const isActiveSubscription = computed(() => props.subscription?.status?.toLowerCase() === 'active')
const isExpiredSubscription = computed(() => props.subscription?.status?.toLowerCase() === 'expired')

const paymentNextActionDate = computed(() => {
  const charges = props.subscription?.pricing_plan?.charges
  const isRecurringFeeCharge = charges?.find(charge => charge?.type === 'RECURRING_FEE')
  if (isRecurringFeeCharge) {
    const localDate = new Date(props.subscription?.endDate)?.toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
    return localDate
  } else {
    const todayDayOfMonth = new Date().getDate()
    const month = todayDayOfMonth === 1 || todayDayOfMonth === 2 ? new Date().getMonth() : new Date().getMonth() + 1
    const day = 2
    const year = new Date().getFullYear()
    return new Date(year, month, day).toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  }
})
const subscriptionEndDate = computed(() => {
  if (props.subscription?.endDate) {
    return new Date(props.subscription?.endDate)?.toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  }
  return null
})
const productId = computed(() => props.subscription?.product?.id)
const subscriptionNumber = computed(() => props.subscription?.subscription_number)

const onViewDetailsClick = () => {
  router.push({
    name: 'settings-subscription-details',
    query: {
      accommodationId: props.accommodationId,
      productId: productId.value ?? null,
      subscriptionNumber: subscriptionNumber.value,
      productName: productName.value,
    },
  })
}
</script>
