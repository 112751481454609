<template>
  <CustomModal
    :is-modal-open="modalOpen"
    @toggleModal="onCloseClick"
    @onClickAway="onCloseClick"
    :overlay="'dark'"
    :withHeader="false"
    :withFooter="false"
    :noPadding="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[641px] md:tw-w-[641px] tw-h-screen sm:tw-h-auto sm:tw-rounded-xl tw-rounded-none sm:tw-min-h-[740px] tw-min-h-auto'"
  >
    <div
      class="tw-w-[100vw] md:tw-max-w-[641px] md:tw-w-[641px] tw-h-screen sm:tw-h-auto sm:tw-min-h-[680px] tw-min-h-auto"
    >
      <div class="tw-bg-blue-800 sm:tw-pt-10 tw-pt-28 tw-max-w-full">
        <div class="tw-relative tw-mt-6">
          <div
            class="tw-text-center tw-mx-auto tw-max-w-full tw-bg-blue-800 tw-text-white tw-rounded-xl tw-flex tw-flex-col tw-items-center"
          >
            <div class="tw-bg-white tw-px-3 tw-py-2 tw-rounded-full tw-mb-4">
              <BaseIcon
                icon-name="trivago Business Studio"
                :height="23"
                :width="237"
                :viewbox-height="23"
                :viewbox-width="237"
                class="tw-inline-block"
              >
                <TrivagoLogoPlus />
              </BaseIcon>
            </div>
            <p
              class="sm:tw-text-md tw-font-bold tw-text-2xl md:tw-whitespace-nowrap tw-mt-2"
              v-html="$t('navigation_menu_business_studio_plus_header')"
            />
            <p
              class="tw-my-4 tw-mx-12 tw-text-sm tw-font-light"
              v-html="$t('navigation_menu_business_studio_plus_subtext')"
            />
            <div
              class="tw-flex tw-mb-4 tw-px-12 tw-w-full tw-justify-center tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-2"
            >
              <span class="tw-flex tw-justify-start tw-items-baseline" v-for="item in highlights" :key="item">
                <CheckMark height="12px" width="12px" class="tw-inline tw-fill-yellow-700 tw-h-[12px] tw-w-[14px]" />
                <span class="tw-text-sm tw-font-light tw-ml-2 tw-text-left">{{ $t(item) }}</span>
              </span>
            </div>
          </div>
          <div class="tw-mt-6 tw-flex tw-items-center tw-justify-center tw-flex-col tw-text-white">
            <p
              class="tw-uppercase tw-bg-red-800 tw-w-auto tw-font-bold tw-text-sm tw-text-white tw-rounded tw-px-3 tw-py-2"
            >
              {{ $t('business_studio_plus_discount', { DiscountPercentage: '15' }) }}
            </p>
            <p
              class="tw-font-light current-price tw-py-2"
              v-html="
                $t('business_studio_plus_price_discounted', {
                  AnnualPrice: `${CURRENCIES[currency]}${$n(annualPrice / 100, 'currencyCode')}`,
                })
              "
            />
            <p class="tw-font-light tw-line-through">
              {{
                $t('business_studio_plus_price_no_discount', {
                  AnnualPrice: `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}`,
                })
              }}
            </p>
          </div>
          <div class="tw-h-[160px]">
            <img
              :src="StudioPlusYearly"
              alt="Yearly Studio Plus Subscription"
              class="tw-mx-auto tw-absolute tw-bottom-[-7px] tw-right-0 tw-left-0"
              height="140"
              width="252"
            />
          </div>
        </div>
      </div>
      <div class="tw-p-4 tw-flex tw-flex-col tw-items-center tw-mt-6">
        <router-link to="/studio-plus/compare">
          <MainButton :disabled="!isAdmin" @click="redirectToCompare">
            <span class="tw-px-6">{{ $t('studio_plus_start_cta') }}</span>
          </MainButton>
        </router-link>
      </div>

      <p class="tw-text-center tw-text-sm tw-mt-1 sm:tw-mt-0 tw-mb-[30px]">
        {{ $t('business_studio_plus_subscription_options') }}
      </p>
    </div>
  </CustomModal>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { CURRENCIES } from '@/constants/currencies.js'

import appAnalytics from '@/utils/tracking'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogoPlus from '@/components/icons/TrivagoLogoPlus.vue'
import CheckMark from '@/components/icons/CheckMark.vue'
import { selectedAccommodationId } from '@/layouts/queries'

import { useStudioPlusPrice } from '@/components/payment/queries'

const StudioPlusYearly = new URL('/src//assets/img/studio-plus/studio-plus-yearly.svg', import.meta.url)

const router = useRouter()
const store = useStore()
const isAdmin = computed(() => store.state.session?.userRole === 'admin')
const currency = computed(() => store.state.currency)

const { studioPlusPriceMonthly, studioPlusPriceAnnual } = useStudioPlusPrice(selectedAccommodationId)
const annualPrice = computed(() => studioPlusPriceAnnual.value?.price ?? 0)
const monthlyPrice = computed(() => studioPlusPriceMonthly.value?.price ?? 0)
const modalOpen = ref(false)
const highlights = [
  'studio_plus_mp_higlight_1',
  'studio_plus_mp_highlight_2',
  'studio_plus_mp_highlight_3',
  'business_studio_plus_free_trial_highlights',
]

onMounted(() => {
  modalOpen.value = true
})

const onCloseClick = () => {
  window.sessionStorage?.setItem?.('studio_plus_popup_skip', true)
  modalOpen.value = false
}

const redirectToCompare = () => {
  router.push({ name: 'studio-plus-compare' })
  appAnalytics.track(appAnalytics.events.SP_HOMEPAGE_POPUP_CLICKED, {
    item_id: selectedAccommodationId.value,
  })
}
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
