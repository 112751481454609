<template>
  <button
    :type="type"
    :class="sizeClass"
    :data-testid="dataTestId"
    class="tw-text-center tw-cursor-pointer tw-transition-colors tw-duration-300"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    size: {
      type: String,
      default: 'big',
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
  computed: {
    sizeClass() {
      if (this.size === 'noPadding') return ''
      if (this.size === 'small') return 'tw-px-4 tw-py-1 tw-text-xs tw-rounded'
      if (this.size === 'medium') return 'tw-px-6 tw-py-[0.44rem] tw-text-sm tw-rounded-lg'
      else return 'tw-px-4 tw-py-[11px] tw-big-button-text tw-rounded-lg'
    },
  },
}
</script>

<style scoped>
button:disabled {
  pointer-events: none;
}
button:focus {
  @apply tw-outline-0;
}
</style>
