<template>
  <Notification
    :description="$t('notification_connectivity_issue_message')"
    :header="$t('notification_connectivity_issue_heading')"
    v-bind="$attrs"
  >
    <template #ctaContent>
      <div class="tw-mt-3 md:tw-mt-0">
        <BaseLinkButton
          :href="supportCenterUrl"
          rel="noopener noreferrer"
          target="_blank"
          class="tw-block tw-bg-blue-800 hover:tw-no-underline tw-text-white hover:tw-text-white tw-font-bold tw-px-6 tw-py-2 tw-text-sm tw-rounded-lg hover:tw-bg-blue-900"
        >
          {{ $t('notification_action_contact_us') }}
        </BaseLinkButton>
      </div>
    </template>
  </Notification>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Notification from './Notification.vue'
import BaseLinkButton from '@/components/base/buttons/BaseLinkButton.vue'

import { getSupportCenterUrl } from '@/utils/Utility.js'

const store = useStore()
const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))
</script>
