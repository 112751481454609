<template>
  <BaseCard noPadding class="tw-my-4 tw-pb-10 tw-px-10 tw-pt-6">
    <div v-if="isInitialLoading">
      <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true" />
    </div>
    <div
      v-else-if="(subscriptionDetailsError && isErrorAccommodationDetails) || isError"
      class="tw-text-red-800"
      v-html="$t('errors.wrong_data_from_app')"
    />

    <div v-else>
      <a
        class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-800 tw-flex tw-py-2.5"
        @click="router.push({ name: 'settings-subscriptions' })"
        @keydown.enter="router.push({ name: 'settings-subscriptions' })"
      >
        ← {{ $t('setup_back') }}</a
      >

      <div class="tw-py-8 tw-border-b tw-border-gray-300">
        <h3 class="tw-text-xl tw-font-bold tw-mb-4">
          {{
            currentAccommodation?.name ?? $t('subscriptions.noAccommodationName', { accommodationId: accommodationId })
          }}
        </h3>
        <img v-if="!productName.toLowerCase().includes('pro')" :src="getLogo(productName)" alt="Logo" height="23" />
        <p v-else class="tw-text-xl tw-font-bold">{{ $t('PRO Package') }}</p>
      </div>
      <SubscriptionDetailsStatus
        :subscription="studioPlusCampaign"
        :accommodationId="accommodationId"
        :productName="productName"
        :isRateConnectActive="isRateConnectActive"
        :currentAccommodation="currentAccommodation"
      />
      <TransactionHistory :subscriptionNumber="subscriptionNumber" />
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useSubscriptionDetails, useAccommodationDetails } from '@/components/settings/subscriptions/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import { useAccommodationsByOrgId } from '@/layouts/queries'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import SubscriptionDetailsStatus from '@/components/settings/subscriptions/SubscriptionDetailsStatus.vue'
import TransactionHistory from '@/components/settings/subscriptions/TransactionHistory.vue'

const rateConnectLogo = new URL('/src/assets/img/logos/rate-connect-logo.svg', import.meta.url)
const businessPlusLogo = new URL('/src/assets/img/logos/business-plus-logo.svg', import.meta.url)

const router = useRouter()
const store = useStore()

const { productId, subscriptionNumber, productName } = router.currentRoute.value.query
const accommodationId = Number(router.currentRoute.value.query?.accommodationId)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === accommodationId)
)

const { studioPlusCampaign, isLoading, isError, isFetching } = useStudioPlusCampaign({ value: accommodationId })

const isRateConnectActive = computed(() => {
  return subscriptionDetails?.value?.status?.toLowerCase() === 'active'
})

const { subscriptionDetails, subscriptionDetailsFetching, subscriptionDetailsError } = useSubscriptionDetails(
  accommodationId,
  productId,
  subscriptionNumber
)

const isInitialLoading = computed(() => {
  if (productName.includes('Rate Connect') || productName.includes('rateConnect')) {
    return (
      (subscriptionDetailsFetching.value && isFetchingAccommodationDetails.value) ||
      subscriptionDetails.value === undefined
    )
  } else {
    return isLoading.value || isFetching.value
  }
})

const { isFetching: isFetchingAccommodationDetails, isError: isErrorAccommodationDetails } = useAccommodationDetails(
  accommodationId,
  productName
)

const getLogo = product => {
  if (product.includes('Rate Connect') || productName.includes('rateConnect')) {
    return rateConnectLogo
  } else if (product === 'Business Studio+') {
    return businessPlusLogo
  }
}

onMounted(() => {
  if (!accommodationsData.value.find(item => item.accommodationId === accommodationId)) {
    router.push({ name: 'settings-subscriptions' })
  }
})
</script>
