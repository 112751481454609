<template>
  <div class="tw-my-[40px]">
    <div class="tw-mb-8">
      <div class="tw-flex tw-items-center tw-py-2">
        <BaseIcon :height="24" :width="24" icon-name="dashboard-performance" class="tw-mr-1">
          <DashboardPerformance />
        </BaseIcon>
        <h4 class="tw-text-xl tw-font-bold">{{ $t('app.rateInsights.name') }}</h4>
        <UiTooltip tooltip-position="top" :label="$t('rate_insights_tooltip')" class="tw-cursor-pointer tw-ml-1">
          <BaseIcon
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-900 tw-h-[22px] tw-w-[22px] tw-ml-1"
            icon-name="information-outline"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p class="tw-flex tw-w-full tw-text-base tw-font-normal">
        {{ $t('published_rates_shown') }}
      </p>
    </div>
    <BaseCard>
      <RatesFilters
        :filters="filters"
        :aggregation="aggregation"
        :competitors="competitors"
        :channels="selectedChannels"
        :marketRate="marketRate"
        :dateRateOptions="timeRangeOptions"
        :selectedTimeRange="selectedTimeRange"
        :selectedAggregation="selectedAggregation"
        :disabled="isWaitingMode"
        @setMarketRate="value => setMarketRate(value)"
        @setAggregation="value => setAggregation(value)"
        @setChannelsIds="value => setChannelsIds(value)"
        @setCompetitorsIds="value => setCompetitorsIds(value)"
        @setTimeRange="value => setTimeRange(value)"
      />
      <div class="tw-bg-gray-200 tw-w-full tw-h-[1px] tw-flex tw-my-9" />
      <div
        v-if="isWaitingMode || hasNoRates"
        class="tw-bg-blue-200 tw-rounded-lg tw-py-3 tw-mt-10 tw-flex tw-items-center"
      >
        <BaseIcon
          :height="20"
          :width="20"
          icon-name="information"
          class="tw-mt-0.5 tw-w-[90px] md:tw-w-[45px] tw-fill-blue-700 tw-mt-[3px]"
        >
          <Information />
        </BaseIcon>
        <p class="tw-text-base tw-leading-5" v-html="$t('app_emptystate_title')" />
      </div>
      <GraphContainer
        v-if="!isRatesLoading || rates === undefined"
        :competitors="competitorsWithColors"
        :series="getData(rates === undefined ? {} : rates, marketRate)"
        :categories="getDays(rates === undefined ? {} : rates)"
        :days="getDays(rates === undefined ? {} : rates)"
        :filters="customFilters"
        :selectTimestamp="value => setTimestamp(value)"
        :is-waiting-mode="isWaitingMode"
        :accommodation-id="selectedAccommodationId"
        :currency="currency"
      />
      <ChannelComponent
        v-if="!isRatesLoading"
        :channels="selectedChannels"
        :competitors="competitorsWithColors"
        :filters="customFilters"
        :rates="getRates(rates, aggregationData, timestamp)"
        :formattedDate="getDatesToShow(rates, aggregationData, timestamp)"
      />
      <FullScreenLoader v-show="isRatesFetching" />
    </BaseCard>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import {
  useRates,
  useRateDefaults,
  useRateFilters,
  useSelectedBookingSites,
  useCompetitors,
} from '@/components/rateInsights/queries'
import { useRateInsights } from '@/components/rateInsights/useRateInsights.js'
import { calculateDates, getDateRange } from '@//components/rateInsights/utils.js'

import appAnalytics from '@/utils/tracking'
import filter from 'lodash/filter'
import max from 'lodash/max'

import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import RatesFilters from '@/components/rateInsights/rates/RatesFilters.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import DashboardPerformance from '@/components/icons/DashboardPerformance.vue'
import ChannelComponent from '@/components/rateInsights/rates/ChannelComponent.vue'
import GraphContainer from '@/components/rateInsights/rates/GraphContainer.vue'
import Information from '@/components/icons/Information.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'

const store = useStore()

const { aggregation } = useRateDefaults()
const { filters } = useRateFilters()
const { selectedChannels } = useSelectedBookingSites(selectedAccommodationId)
const { competitors } = useCompetitors(selectedAccommodationId)

const filterValues = Object.assign(
  {},
  ...filters.value.map(item => ({ [item.name]: item.options.filter(item => item.default)[0] }))
)
const locale = computed(() => store.state.locale.language)
const isWaitingMode = computed(() => selectedChannels?.value?.length === 0)
const hasNoRates = ref(false)
const timeRangeOptions = ref([
  { id: 0, name: 'past_x_days', amount: 7, value: { past: true, unit: 'days' }, default: false },
  { id: 1, name: 'next_x_days', amount: 7, value: { past: false, unit: 'days' }, default: true },
  { id: 2, name: 'past_x_days', amount: 30, value: { past: true, unit: 'days' }, default: false },
  { id: 3, name: 'next_x_days', amount: 30, value: { past: false, unit: 'days' }, default: false },
  { id: 4, name: 'past_x_months', amount: 2, value: { past: true, unit: 'months' }, default: false },
  { id: 5, name: 'next_x_months', amount: 2, value: { past: false, unit: 'months' }, default: false },
  { id: 6, name: 'past_x_months', amount: 3, value: { past: true, unit: 'months' }, default: false },
  { id: 7, name: 'next_x_months', amount: 3, value: { past: false, unit: 'months' }, default: false },
])
const selectedTimeRange = ref(timeRangeOptions.value.filter(item => item.default)[0])
const marketRate = ref(filterValues['market_rates'].name || 'lowest')
const selectedAggregation = ref(aggregation?.value.filter(item => item.default)[0])
const aggregationData = ref(selectedAggregation?.value.name)
const channelsIds = ref(selectedChannels?.value?.map(item => item.id).toString())
const { allFilters } = useRateInsights()
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const allCompetitors = computed(() => {
  const myAccommodation = [
    {
      id: selectedAccommodationId.value,
      name: currentAccommodation.value?.name || `Hotel Id ${selectedAccommodationId.value}`,
    },
  ]
  return [...myAccommodation, ...competitors.value]
})

watch(
  selectedChannels,
  newValue => {
    channelsIds.value = newValue?.map(item => item.id).toString()
  },
  { deep: true, immediate: true }
)
const competitorsIds = computed(() => allCompetitors?.value?.map(item => item.id).toString())
const colors = ['#007BC1', '#8291D3', '#FC9E15', '#599A19', '#FF8381', '#3F57BD']
const competitorsWithColors = computed(() => {
  return allCompetitors.value?.map((item, i) => ({ ...item, color: colors[i] }))
})
const currency = computed(() => store.state.currency)

const startDate = ref(
  calculateDates({
    amount: selectedTimeRange.value.amount,
    unit: selectedTimeRange.value.value,
  }).from
)
const endDate = ref(
  calculateDates({
    amount: selectedTimeRange.value.amount,
    unit: selectedTimeRange.value.value,
  }).to
)

const timestamp = ref(
  calculateDates({
    amount: selectedTimeRange.value.amount,
    unit: selectedTimeRange.value.value,
  }).timestamp
)

const customFilters = computed(() => {
  return {
    channelIds: channelsIds.value,
    breakfast: allFilters.value['breakfast']?.name,
    roomType: allFilters.value['room_type']?.name,
    marketRates: marketRate.value,
  }
})

const setTimeRange = value => {
  selectedTimeRange.value = value
  startDate.value = calculateDates({ amount: value.amount, unit: value.value }).from
  endDate.value = calculateDates({ amount: value.amount, unit: value.value }).to
  timestamp.value = calculateDates({ amount: value.amount, unit: value.value }).timestamp
}
const setMarketRate = value => {
  marketRate.value = value
}
const setAggregation = value => {
  selectedAggregation.value = value
  aggregationData.value = value.name
  timestamp.value = calculateDates({
    amount: selectedTimeRange.value.amount,
    unit: selectedTimeRange.value.value,
  }).timestamp
}
const setChannelsIds = value => {
  channelsIds.value = value
}
const setCompetitorsIds = value => {
  if (!value.includes(selectedAccommodationId.value)) {
    competitorsIds.value = selectedAccommodationId.value + ',' + value
  } else {
    competitorsIds.value = value
  }
}

const setTimestamp = newTimestamp => {
  timestamp.value = newTimestamp
}

const { isRatesLoading, rates, isRatesFetching } = useRates(marketRate, selectedAccommodationId, {
  aggregation: aggregationData,
  channels: channelsIds,
  competitors: competitorsIds,
  currency: currency,
  from: startDate,
  to: endDate,
})

const getRates = (allRates, aggregation, currentTimestamp) => {
  const competitorsArr = competitorsIds.value.split(',')
  const channelsArr = channelsIds.value.split(',')
  const ratesForEmptyData = {}
  for (const item of competitorsArr) {
    ratesForEmptyData[item] = {}
    for (const key of channelsArr) {
      ratesForEmptyData[item][key] = {
        double: 0,
        double_breakfast: 0,
        multi: 0,
        multi_breakfast: 0,
        single: 0,
        single_breakfast: 0,
      }
    }
  }

  if (aggregation === 'day') {
    return allRates[currentTimestamp]
      ? fillMissingData(ratesForEmptyData, allRates[currentTimestamp])
      : ratesForEmptyData
  } else {
    const keys = Object.keys(allRates)
    const currentWeek =
      keys.find(item => item.startsWith(currentTimestamp)) !== undefined
        ? keys.find(item => item.startsWith(currentTimestamp))
        : keys[0]
    return allRates[currentWeek] ? fillMissingData(ratesForEmptyData, allRates[currentWeek]) : ratesForEmptyData
  }
}

const fillMissingData = (ratesForEmptyData, allRates) => {
  for (const [key] of Object.entries(ratesForEmptyData)) {
    if (allRates[key]) {
      ratesForEmptyData[key] = allRates[key]
    }
  }
  return ratesForEmptyData
}

const getDatesToShow = (allRates, aggregation, timestamp) => {
  if (aggregation === 'day') {
    let date = new Date(timestamp * 1000)
    return date.toLocaleString(locale.value, { day: 'numeric', month: 'short', timeZone: 'UTC' })
  } else {
    const keys = Object.keys(allRates)
    const currentWeek = keys.find(item => item.startsWith(timestamp))?.split('-')
    let startDate, endDate
    if (currentWeek?.length > 0) {
      startDate = new Date(currentWeek[0] * 1000)
      endDate = new Date(currentWeek[1] * 1000)
    } else {
      startDate = new Date(keys[0]?.split('-')[0] * 1000)
      endDate = new Date(keys[0]?.split('-')[1] * 1000)
    }

    return `${startDate.toLocaleString(locale.value, {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
    })} - ${endDate.toLocaleString(locale.value, { day: 'numeric', month: 'short', timeZone: 'UTC' })}`
  }
}

const getDays = allRates => {
  let days = Object.keys(allRates)
  let currentDays
  days = getDateRange({
    days,
  })

  if (days.length === 0) {
    currentDays = Array.from(Array(7).keys()).map(idx => {
      const d = new Date()
      d.setDate(d.getDate() - d.getDay() + idx)
      return {
        date: new Date(d),
        timestamp: new Date(d).getTime(),
      }
    })
  }

  return days.length > 0 ? days : currentDays
}

const getData = (allRates, marketRates) => {
  const competitors = JSON.parse(JSON.stringify(competitorsWithColors.value))
  const channels = selectedChannels ? selectedChannels?.value : []
  const breakfast = customFilters.value.breakfast
  const room_type = customFilters.value.roomType

  const rateType = `${room_type}${breakfast === 'included' ? `_breakfast` : ''}`
  let days = Object.keys(allRates)
  const series = []

  if (days.length) {
    days = getDays(allRates)
  }

  if (competitors.length) {
    competitors.map(competitor => {
      const competitorDaySeries = []
      const competitorId = competitor.id
      const competitorColor = competitor.color

      Object.keys(allRates).map(timestamp => {
        const competitorChannelRates = allRates[timestamp][competitorId]
        const competitorDayChannelRates = []

        if (channels.length) {
          channels.map(channel => {
            const channelRates =
              competitorChannelRates && competitorChannelRates[channel.id] ? competitorChannelRates[channel.id] : null
            competitorDayChannelRates.push(channelRates ? channelRates[rateType] : 0)
          })
        }

        let rateShown = 0
        if (marketRates === 'lowest' || marketRates === 'home') {
          rateShown = Math.min.apply(null, filter(competitorDayChannelRates, Boolean)) // all minimum values excluding 0's
        } else {
          rateShown = max(competitorDayChannelRates)
        }

        rateShown = Math.round(rateShown / 100)

        if (rateShown === Infinity || rateShown === 0) {
          rateShown = null
        }

        competitorDaySeries.push(rateShown)
      })

      series.push({
        data: competitorDaySeries,
        competitorId: competitorId,
        color: competitorColor,
        marker: {
          symbol: 'circle',
        },
      })
    })
  }

  const seriesLength = series.length
  let seriesHaveDataTotal = seriesLength
  const allSeries = [].concat(series)

  if (seriesLength) {
    series.map(serie => {
      let itemsHaveNoValueTotal = 0
      const itemsTotal = serie.data.length

      serie.data.map(val => {
        if (!val) {
          itemsHaveNoValueTotal++
        }
      })

      if (itemsHaveNoValueTotal === itemsTotal || serie.data.length === 0) {
        seriesHaveDataTotal--
        const serieIndex = allSeries.indexOf(serie)
        allSeries.splice(serieIndex, 1)
      }
    })
  }

  // in case no series is available, we pass random data to display empty chart
  seriesHaveDataTotal === 0 ? (hasNoRates.value = true) : (hasNoRates.value = false)

  if (seriesHaveDataTotal === 0 && rates?.value?.length === 0) {
    appAnalytics.track(appAnalytics.events.RI_NO_DATA_FOR_FILTERS, {
      accommodationId: selectedAccommodationId.value,
    })
  }
  return seriesHaveDataTotal > 0
    ? allSeries
    : [
        {
          data: [50, null, null, null, null, 100],
          connectNulls: false,
          color: 'rgba(0,0,0,0)',
          marker: {
            width: '1px',
          },
          enableMouseTracking: false,
        },
      ]
}
</script>
