<template>
  <div>
    <StudioPlusSubscriptionHeader :isCancelled="isCancelledSubscription" :isExpired="isExpiredSubscription" />
    <BaseCard noPadding>
      <div class="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row">
        <div class="tw-flex tw-pb-0 lg:tw-pb-8 tw-p-8 tw-flex-col lg:tw-flex-row">
          <p class="tw-font-bold tw-text-xl tw-text-center tw-mb-3.5 lg:tw-hidden">
            {{ $t('Don’t let go of your Business Studio+ premium benefits!') }}
          </p>
          <div class="tw-min-h-[216px] tw-flex-basis-1/4 tw-flex tw-justify-end tw-mb-3.5 lg:tw-mb-0">
            <img
              :src="
                isCancelledSubscription
                  ? StudioPlusSubscriptionInactiveCancelled
                  : StudioPlusSubscriptionInactiveExpired
              "
              alt="Studio Plus Subscription"
              class=""
              height="216"
              width="252"
            />
          </div>
          <div class="tw-flex-basis-3/4 lg:tw-ml-12 tw-mb-3.5 lg:tw-mb-0">
            <p class="tw-font-bold tw-text-xl tw-hidden lg:tw-flex tw-mb-6">
              {{
                isCancelledSubscription
                  ? $t('subscription_reactivate_benefits_header')
                  : $t('subscription_expired_benefits_header')
              }}
            </p>
            <ul class="tw-mb-6">
              <li
                v-for="(item, index) in benefitsList"
                :key="index"
                class="tw-flex tw-items-center tw-py-1 first:tw-pt-0 last:tw-pb-0"
              >
                <BaseIcon
                  :height="24"
                  :width="24"
                  icon-name="checkmark-blue"
                  class="tw-text-gray-900 tw-stroke-gray-900 tw-w-[24px]"
                >
                  <CheckmarkBlue />
                </BaseIcon>
                <span class="lg:tw-pl-2 tw-pl-4">{{ $t(item) }}</span>
              </li>
            </ul>
            <div class="tw-flex tw-flex-col md:tw-flex-row">
              <GhostButton size="medium" @click="redirectToContactUs" class="md:tw-mr-4 md:tw-mb-0 tw-mb-4">{{
                $t('navigation_menu_contact_us')
              }}</GhostButton>
              <MainButton size="medium" @click="openReactivationModal(false)">{{
                isCancelledSubscription ? $t('keep_benefits_cta') : $t('unlock_now_cta')
              }}</MainButton>
            </div>
          </div>
        </div>
        <div class="lg:tw-hidden tw-px-8 tw-pb-2 tw-bg-gray-100" :class="showStatus ? '' : 'tw-rounded-b-lg'">
          <button class="tw-flex tw-items-center tw-justify-between tw-mt-3 tw-w-full" @click="toggleStatus()">
            {{ $t('studio_plus_subscription_status_header') }}
            <BaseIcon
              icon-name="chevron-right"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              :icon-color="'#6C707A'"
              :class="showStatus ? 'tw-rotate-[270deg]' : 'tw-rotate-90'"
            >
              <ChevronRight />
            </BaseIcon>
          </button>
        </div>
        <div :class="getStyles" class="tw-p-8 tw-min-w-[300px] tw-flex-col tw-justify-center">
          <div class="tw-mb-8">
            <p class="tw-font-bold tw-mb-4">{{ $t('studio_plus_subscription_status_header') }}{{ ': ' }}</p>
            <p class="tw-text-sm tw-flex tw-items-center">
              <span
                :class="isCancelledSubscription ? 'tw-bg-orange-700' : 'tw-bg-red-800'"
                class="tw-inline-flex tw-h-3.5 tw-w-3.5 tw-mr-2 tw-rounded-full"
              />{{
                isCancelledSubscription
                  ? $t('business_studio_plus_active_until_date', { ActiveDate: expirationDate })
                  : $t('subscription_expiration_date', { ExpirationDate: expirationDate })
              }}
            </p>
            <GhostButton size="small" class="tw-px-9 tw-w-full tw-mt-4" @click="openReactivationModal(true)">{{
              'Reactivate Subscription'
            }}</GhostButton>
          </div>
          <div v-if="studioPlusCampaign?.partnerId">
            <div class="tw-mb-4 tw-flex tw-items-center">
              <p class="tw-font-bold">
                {{ $t('connectivity_provider_header') }}
              </p>
              <UiTooltip tooltip-position="top" :label="$t('studio_plus_connectivity_tooltip')">
                <BaseIcon
                  :height="16"
                  :width="16"
                  icon-name="information-outline"
                  class="tw-fill-none tw-stroke-gray-900 tw-h-[16px] tw-w-[16px] tw-ml-1"
                >
                  <InformationOutline />
                </BaseIcon>
              </UiTooltip>
            </div>
            <img
              :src="`https://imgcy.trivago.com/e_trim/f_auto,dpr_2,bo_1px_solid_transparent,c_pad,h_34,q_auto,w_81/partnerlogos-s3/${studioPlusCampaign?.partnerId}`"
              alt="partner logo"
              height="30"
              class="tw-max-h-[30px]"
            />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import { useStudioPlusCampaign } from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { getSupportCenterUrl } from '@/utils/Utility.js'

import ChevronRight from '@/components/icons/ChevronRight.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import CheckmarkBlue from '@/components/icons/CheckmarkBlue.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import StudioPlusSubscriptionHeader from '@/components/studioPlus/StudioPlusSubscriptionHeader.vue'

import appAnalytics from '@/utils/tracking'

const store = useStore()
const emit = defineEmits(['openReactivationModal'])
const openReactivationModal = isReactivationBtn => {
  appAnalytics.track(
    isReactivationBtn
      ? appAnalytics.events.SP_REACTIVATION_BUTTON_HP
      : appAnalytics.events.SP_REACTIVATION_KEEP_BENEFITS,
    {
      accommodationId: selectedAccommodationId.value,
      expirationDate: expirationDate.value,
      isExpired: isExpiredSubscription.value,
    }
  )
  emit('openReactivationModal')
}

const showStatus = ref(false)
const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))
const StudioPlusSubscriptionInactiveCancelled = new URL(
  '/src/assets/img/studio-plus/reactivation-banner-image-cancelled.svg',
  import.meta.url
)
const StudioPlusSubscriptionInactiveExpired = new URL(
  '/src/assets/img/studio-plus/reactivation-banner-image-expired.svg',
  import.meta.url
)

const { studioPlusCampaign, isCancelledSubscription, isExpiredSubscription } =
  useStudioPlusCampaign(selectedAccommodationId)

const expirationDate = computed(() => {
  return new Date(studioPlusCampaign.value?.endDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const toggleStatus = () => {
  showStatus.value = !showStatus.value
}

const redirectToContactUs = () => {
  appAnalytics.track(appAnalytics.events.SP_REACTIVATION_CONTACT_US, {
    accommodationId: selectedAccommodationId.value,
    expirationDate: expirationDate.value,
    isExpired: isExpiredSubscription.value,
  })
  if (isExpiredSubscription.value) {
    return window.open(
      'https://outlook.office365.com/owa/calendar/TrivagoTBSonboarding@trvoffice.onmicrosoft.com/bookings/',
      '_blank'
    )
  } else {
    return window.open(supportCenterUrl.value, '_blank')
  }
}

const benefitsList = ref([
  'subscription_reactivate_benefit_1',
  'subscription_reactivate_benefit_2',
  'subscription_reactivate_benefit_3',
  'subscription_reactivate_benefit_4',
])

const getStyles = computed(() => {
  let classes = []
  if (showStatus.value) {
    classes.push('tw-flex lg:tw-flex tw-rounded-b-lg shadow')
  } else {
    classes.push('tw-hidden lg:tw-flex tw-rounded-r-lg')
  }
  if (isCancelledSubscription.value) {
    classes.push('tw-bg-orange-200')
  } else if (isExpiredSubscription.value) {
    classes.push('tw-bg-red-200')
  }
  return classes.join(' ')
})
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 4px -3px #00000040 inset;
}
</style>
