<template>
  <div>
    <SearchBar
      :searchValue="searchValue"
      @updateSearchValue="updateSearchValue"
      @search="search"
      :disabled="staticMessage === 'unauthorized'"
    />
    <AdminLoadingSkeleton v-if="isLoading" />
    <ResultsList
      v-else-if="accommodationData && !isLoading && paidSubscriptions"
      :accommodationData="accommodationData"
      :subscriptions="paidSubscriptions"
      @updateStaticMessage="updateStaticMessage"
      @showError="showError"
      @resetState="resetState"
      @fetchData="fetchData"
    />
    <StaticMessage v-else :staticMessageType="staticMessage" />
  </div>
</template>
<script setup>
import { ref } from 'vue'

import PremiumService from '@/services/PremiumService'
import AdminService from '@/services/AdminService'

import SearchBar from '@/components/adminTool/SearchBar.vue'
import ResultsList from '@/components/adminTool/ResultsList.vue'
import AdminLoadingSkeleton from '@/components/loadingSkeletons/AdminLoadingSkeleton.vue'
import StaticMessage from '@/components/adminTool/StaticMessage.vue'

const staticMessage = ref('welcome')
const searchValue = ref('')
const paidSubscriptions = ref(null)
const accommodationData = ref(null)
const isLoading = ref(false)

const updateSearchValue = value => {
  searchValue.value = value

  if (value.length === 0) {
    updateStaticMessage('welcome')
  }
}

const resetState = () => {
  updateSearchValue('')
  fetchData()
}

const showError = error => {
  const errorStatus = error.response?.status

  switch (errorStatus) {
    case 404:
      if (searchValue.value === '') {
        updateStaticMessage('welcome')
      } else {
        updateStaticMessage('noResults')
      }
      break
    case 401:
      updateSearchValue('')
      updateStaticMessage('unauthorized')
      break
    default:
      updateSearchValue('')
      updateStaticMessage('genericError')
  }
}

const updateStaticMessage = type => {
  staticMessage.value = type
}

const fetchData = () => {
  fetchSubscriptions(searchValue.value)
  fetchAccommodation(searchValue.value)
}

const fetchSubscriptions = async accommodationId => {
  await PremiumService.getPaidSubscriptions(accommodationId)
    .then(result => {
      paidSubscriptions.value = result.data
      isLoading.value = false
    })
    .catch(error => {
      showError(error)
      isLoading.value = false
      paidSubscriptions.value = null
    })
}

const fetchAccommodation = async accommodationId => {
  await AdminService.search(accommodationId)
    .then(result => {
      accommodationData.value = result.data
      isLoading.value = false
    })
    .catch(error => {
      showError(error)
      isLoading.value = false
      accommodationData.value = null
    })
}

const search = async () => {
  isLoading.value = true
  if (staticMessage.value === 'unauthorized') return
  fetchData()
}
</script>
