<template>
  <div :id="containerName"></div>
</template>

<script setup>
import { nextTick, watch } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

const props = defineProps({
  series: {
    type: Array,
    default() {
      return []
    },
  },
  isWaitingMode: {
    type: Boolean,
    required: true,
    default: true,
  },
  language: String,
  categories: {
    type: Array,
    default() {
      return []
    },
  },
})

const containerName = 'duration-graph-container'

const drawChart = (series, categories, language) => {
  if (!series || !categories) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerName)) return
      Highcharts.chart(containerName, {
        title: null,
        chart: {
          alignTicks: true,
          type: 'column',
          scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1,
          },
          plotBorderWidth: 0,
          style: {
            fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
          },
        },
        credits: {
          enabled: false,
        },
        series: series,
        xAxis: {
          categories: categories,
          gridLineColor: '#E5F2F6',
          lineColor: '#E5F2F6',
          tickColor: '#E5F2F6',
          tickLength: 0,
          gridLineWidth: 1,
          labels: {
            style: { fontSize: 16, color: '#000' },
          },
        },
        yAxis: {
          title: null,
          gridLineColor: '#E5F2F6',
          lineColor: '#E5F2F6',
          tickColor: '#E5F2F6',
          tickLength: 0,
          gridLineWidth: 1,
          min: 0,
          max: 100,
          tickPositions: [0, 20, 40, 60, 80, 100],
          labels: {
            formatter: function () {
              return `${new Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(
                this.value / 100
              )}`
            },
            style: {
              fontSize: '14',
            },
          },
          stackLabels: {
            style: {
              color: '#005FA3',
              fontSize: '24',
              textOutline: 0,
            },

            formatter: function () {
              if (this.total < 1 && this.total !== 0)
                return `<p class="tw-flex tw-text-blue-900"><${new Intl.NumberFormat(language, {
                  style: 'percent',
                  maximumFractionDigits: 0,
                }).format(1 / 100)}</p>`
              else if (this.total === 0) {
                return ''
              } else {
                return `
                  <p class="tw-flex tw-text-blue-900">${
                    props.isWaitingMode
                      ? ''
                      : new Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(
                          this.total / 100
                        )
                  }</p>`
              }
            },
            enabled: true,
            y: 0,
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            maxPointWidth: '90',
            borderRadius: 4,
          },
          series: {
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            color: '#368CDC',
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0) {
      drawChart(newProps.series, newProps.categories, newProps.language)
    }
  },
  { deep: true, immediate: true }
)
</script>
