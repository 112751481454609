<template>
  <div class="tw-mt-6">
    <p class="tw-text-gray-700 tw-pb-2 tw-text-xs required" v-html="$t('required_information')" />
    <p class="tw-font-bold tw-text-base" v-html="$t('business_studio_plus_cancellation_question')" />
    <div class="tw-pb-6 tw-pt-4">
      <div v-for="item in reasons" class="tw-py-1.5 first:tw-pt-0 last:tw-pb-0" :key="item.id">
        <label
          :id="`reason${item.id}`"
          class="hover:tw-cursor-pointer tw-flex tw-items-center"
          :class="{ 'selected-value': item.id === reason?.id }"
        >
          <input
            :id="`reason${item.id}`"
            name="cancellationReason"
            type="radio"
            :value="item.value"
            class="tw-hidden"
            @change="updateReason(item)"
          />
          {{ item.label }}</label
        >
      </div>
    </div>
    <div v-if="reason?.id === 5 || reason?.id === 3">
      <p class="tw-font-bold" v-html="$t('reason_textbox')" />
      <div class="tw-pt-4 tw-pb-6">
        <textarea
          class="tw-outline-none tw-w-full tw-border tw-border-gray-500 tw-rounded-md shadow tw-p-2 tw-max-h-[150px]"
          placeholder="Text"
          @input="updateReasonInfo($event.target.value)"
          ref="reasonExplanation"
          style="height: 56px"
        />
      </div>
    </div>
    <div>
      <p class="tw-font-bold">
        {{ type === 'PLUS' ? $t('feedback_textbox') : $t('feedback_textbox_pro') }}
      </p>
      <div class="tw-pt-4">
        <textarea
          class="tw-outline-none tw-w-full tw-border tw-border-gray-500 tw-rounded-md shadow tw-p-2 tw-max-h-[150px]"
          placeholder="Text"
          @change="updateImprovements($event.target.value)"
          ref="additionalInfo"
          style="height: 56px"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['updateReason', 'updateReasonInfo', 'updateImprovements'])

const reasonExplanation = ref(null)
const additionalInfo = ref(null)

const updateReason = item => emit('updateReason', item)
const updateReasonInfo = item => {
  reasonExplanation.value.style.height =
    reasonExplanation?.value.scrollHeight > 56 ? reasonExplanation?.value.scrollHeight + 'px' : '56px'
  emit('updateReasonInfo', item)
}
const updateImprovements = item => {
  additionalInfo.value.style.height =
    additionalInfo?.value.scrollHeight > 56 ? additionalInfo?.value.scrollHeight + 'px' : '56px'
  emit('updateImprovements', item)
}
defineProps({
  reasons: Array,
  reason: [Object, null],
  reasonInfo: String,
  type: String,
})
</script>
<style scoped>
label:before {
  @apply tw-mr-3 tw-rounded-full tw-h-[24px] tw-w-[24px] tw-inline-flex;
  content: '';
  border: 1px solid theme('colors.gray.500');
}
label.selected-value:before {
  border: 8px solid theme('colors.blue.800');
}
.shadow {
  box-shadow: 0px 1px 3px 0px #0000001a;
}
.required :deep(span) {
  @apply tw-text-red-800;
}
p :deep(span) {
  color: theme('colors.red.800');
}
</style>
