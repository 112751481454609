<template>
  <div class="tw-fixed tw-bg-white tw-w-full tw-shadow-mdLg tw-z-10">
    <div class="tw-flex tw-justify-between tw-items-center tw-mx-auto tw-px-5">
      <div class="tw-duration-100 tw-relative tw-w-auto xxl:tw-w-[30%]">
        <Logo :isLanding="true" />
      </div>
      <nav class="navigation tw-h-16 xl:tw-flex tw-justify-center tw-py-auto tw-hidden tw-w-auto tw-mx-auto">
        <div
          class="dropdown-menu tw-flex tw-relative tw-items-center tw-w-fit tw-pr-[30px] tw-text-sm hover:tw-text-blue-800 tw-transition tw-ease-in-out tw-delay-2 hover:tw-cursor-pointer"
          :class="{ 'tw-text-blue-800  tw-fill-blue-800': isMenuOpen }"
          data-testid="header-account-features-menu"
        >
          {{ $t('navigation_menu_account_features') }}
          <BaseIcon
            icon-name="chevron-right"
            :height="14"
            :width="18"
            :viewbox-height="14"
            :viewbox-width="25"
            class="tw-absolute hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-900 hover:tw-fill-blue-800"
          >
            <ChevronRight />
          </BaseIcon>
          <WebMenu :links="accountFeaturesLinks" class="tw-hidden menu-box" />
        </div>
        <div
          class="dropdown-menu tw-flex tw-relative tw-items-center tw-w-fit tw-text-sm tw-pr-[30px] tw-ml-[30px] hover:tw-text-blue-800 tw-transition tw-ease-in-out tw-delay-2 hover:tw-cursor-pointer"
          :class="{ 'tw-text-blue-800 tw-fill-blue-800': isMenuOpen }"
          data-testid="header-solutions-menu"
        >
          {{ $t('navigation_menu_our_solutions') }}
          <BaseIcon
            icon-name="chevron-right"
            :height="14"
            :width="18"
            :viewbox-height="14"
            :viewbox-width="25"
            class="tw-absolute hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-900 hover:tw-fill-blue-800"
          >
            <ChevronRight />
          </BaseIcon>
          <WebMenu :links="solutionsLinks" class="tw-hidden menu-box" />
        </div>
        <a
          class="external-link tw-relative tw-flex tw-items-center tw-ml-[30px] tw-text-sm tw-w-fit tw-justify-center hover:tw-no-underline hover:tw-text-blue-800 tw-transition tw-ease-in-out tw-delay-2"
          :href="getSupportCenterUrl(locale.language, 'supportCentre')"
          target="blank"
          data-testid="header-support-center-link"
          >{{ $t('navigation_menu_support_center') }}</a
        >
        <a
          class="external-link tw-relative tw-flex tw-items-center tw-ml-[30px] tw-text-sm tw-w-fit tw-justify-center hover:tw-no-underline hover:tw-text-blue-800 tw-transition tw-ease-in-out tw-delay-2"
          :href="getSupportCenterUrl(locale.language, 'contactUs')"
          target="blank"
          data-testid="header-contact-us-link"
          >{{ $t('navigation_menu_contact_us') }}</a
        >
      </nav>
      <nav class="tw-w-[30%]">
        <ul class="tw-flex tw-items-center tw-justify-end tw-gap-2.5 md:tw-gap-2">
          <LanguageSelector
            size="big"
            class="md:tw-block tw-hidden tw-min-w-[200px]"
            :with-image="true"
            version="transparent"
          />
          <div class="divider" />
          <li>
            <BaseButton
              size="noPadding"
              class="tw-text-lg tw-flex tw-items-center tw-gap-1 sign-button hover:tw-bg-gray-100 tw-px-2 tw-py-1.5 tw-rounded-md"
              @click="onLoginClick"
              @keydown.enter="onLoginClick"
              :dataTestId="'header-login-button'"
            >
              <BaseIcon
                width="24"
                height="24"
                viewbox-height="24"
                viewbox-width="24"
                class="tw-text-blue-800 tw-shrink-0"
              >
                <LockClosedIcon />
              </BaseIcon>
              <span class="tw-leading-5 tw-whitespace-nowrap tw-text-sm">{{ $t('tbs_mp_cta_log_in') }}</span>
            </BaseButton>
          </li>
          <li>
            <MainButton
              size="big"
              no-padding
              class="xl:tw-hidden tw-px-6 sign-button tw-whitespace-nowrap"
              @click="onRegisterClick"
              @keydown.enter="onRegisterClick"
              :dataTestId="'mobile-header-register-button'"
            >
              {{ $t('tbs_mp_cta_register_mobile') }}
            </MainButton>
            <MainButton
              size="medium"
              no-padding
              class="tw-hidden xl:tw-block tw-px-10 tw-whitespace-nowrap"
              @click="onRegisterClick"
              @keydown.enter="onRegisterClick"
              :dataTestId="'header-register-button'"
            >
              {{ $t('tbs_mp_cta_register_mobile') }}
            </MainButton>
          </li>
          <li class="xl:tw-hidden">
            <div
              class="nav-icon tw-h-[23px] tw-w-[30px] tw-relative tw-my-[17px] tw-mx-auto tw-cursor-pointer"
              :class="{ 'nav-icon-open': isMenuOpen }"
              @click="() => toggleMenu(true)"
              @keydown.enter="() => toggleMenu(true)"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <MobileMenu
      :solutionsLinks="solutionsLinks"
      :accountFeaturesLinks="accountFeaturesLinks"
      class="tw-flex tw-items-center"
      :is-menu-open="isMenuOpen"
      :on-clickaway="onClickaway"
    />
  </div>
</template>

<script setup>
import { getSupportCenterUrl } from '@/utils/Utility.js'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

import BaseButton from '@/components/base/buttons/BaseButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import LockClosedIcon from '@/components/icons/LockClosedIcon.vue'
import Logo from '@/components/dashboard/Logo.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import MobileMenu from '@/components/landingPage/MobileMenu.vue'
import WebMenu from '@/components/landingPage/WebMenu.vue'

const FreeBookingLink = new URL('/src/assets/img/nav-icons/free-booking-link.svg', import.meta.url)
const FreeBookingLink_Hover = new URL('/src/assets/img/nav-icons/free-booking-link_hover.svg', import.meta.url)
const RateConnect = new URL('/src/assets/img/nav-icons/rate-connect.svg', import.meta.url)
const RateConnect_Hover = new URL('/src/assets/img/nav-icons/rate-connect_hover.svg', import.meta.url)
const StudioPlus = new URL('/src/assets/img/nav-icons/studio-plus.svg', import.meta.url)

const isMenuOpen = ref(false)
const isMenuMobile = ref(false)
const accountFeaturesLinks = [
  {
    id: 0,
    label: 'navigation_menu_free_booking_links',
    url: '/home/getstarted/free-booking-links',
    icon: FreeBookingLink,
    iconHover: FreeBookingLink_Hover,
    header: 'navigation_menu_free_booking_links_header',
    text: 'navigation_menu_free_booking_links_subtext',
    dataTestId: 'header-free-booking-links-link',
  },
]
const solutionsLinks = ref([
  {
    id: 0,
    label: 'navigation_menu_business_studio_plus',
    url: '/home/getstarted/studio-plus',
    icon: StudioPlus,
    iconHover: StudioPlus,
    header: 'navigation_menu_business_studio_plus_header',
    text: 'navigation_menu_business_studio_plus_subtext',
    dataTestId: 'header-studio-plus-link',
  },
  {
    id: 1,
    label: 'navigation_menu_rate_connect',
    url: '/home/getstarted/rate-connect',
    icon: RateConnect,
    iconHover: RateConnect_Hover,
    header: 'navigation_menu_rate_connect_header',
    text: 'navigation_menu_rate_connect_subtext',
    dataTestId: 'header-rate-connect-link',
  },
])
const store = useStore()
const locale = computed(() => store.state.locale)

const toggleMenu = isMobile => {
  isMenuOpen.value = !isMenuOpen.value
  isMenuMobile.value = isMobile
}
const onClickaway = () => {
  if (isMenuOpen.value) {
    isMenuOpen.value = false
  }
}
const emit = defineEmits(['onLoginClick', 'onRegisterClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)
const onRegisterClick = e => emit('onRegisterClick', e.target.value)
</script>
<style scoped>
.divider {
  @apply md:tw-block tw-hidden tw-h-[48px] tw-w-[1px] tw-bg-gray-300;
}

.navigation .external-link:before {
  content: '';
  display: block;
  position: absolute;
  right: -10px;
  top: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 10px solid theme('colors.gray.300');
  border-radius: 2px;
  clear: both;
}
.navigation .external-link:hover:before {
  border-top: 10px solid theme('colors.blue.800');
}
.navigation .dropdown-menu:hover:after,
.navigation a:hover:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: theme('colors.blue.800');
  position: absolute;
  bottom: 0;
}
.navigation div:hover .menu-box {
  @apply tw-flex;
}
.navigation div:hover svg {
  fill: theme('colors.blue.800');
  transform: translateY(-1px) translateX(-8px) rotate(270deg);
}
.arrow-dropdown {
  top: 1px;
  bottom: 0;
  height: 100%;
  right: 0;
  pointer-events: none;
  transform: translateY(0%) rotate(90deg);
}
.nav-icon {
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.nav-icon span {
  @apply tw-block tw-absolute tw-h-[3px] tw-w-full tw-opacity-100 tw-left-0 tw-rounded-lg;
  background: #37454d;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.nav-icon span:nth-child(2) {
  top: 10px;
  transform-origin: left center;
}

.nav-icon span:nth-child(3) {
  top: 20px;
  transform-origin: left center;
}
.nav-icon-open span:nth-child(1) {
  transform: rotate(45deg);
  top: 2px;
  left: 0;
}
.nav-icon-open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.nav-icon-open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 23px;
  left: 0;
}

.sign-button {
  @media (max-width: 540px) {
    display: none;
  }
}
</style>
