import { computed } from 'vue'
import { useQuery, useMutation } from '@tanstack/vue-query'
import PaymentService from '@/services/PaymentService'
import PremiumService from '@/services/PremiumService'

export function useSubscriptions(organisationId) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['subscriptionsByAccommodation', organisationId.value],
    queryFn: () => PaymentService.fetchSubscriptionsByAccommodations(),
  })

  const subscriptionsByAccommodations = computed(() => data?.value?.data?.data?.subscriptionsByAccommodations ?? [])
  return { subscriptionsByAccommodations, isError, isLoading, isFetching }
}

export function useStudioPlusCampaigns(organisationId) {
  const { isLoading, isFetching, isSuccess, isError, data } = useQuery({
    queryKey: ['studioPlusCampaigns', organisationId],
    queryFn: () => PremiumService.fetchStudioPlusCampaignsForOrganisation(),
  })

  const studioPlusCampaigns = computed(() => data?.value?.data ?? [])

  return {
    isStudioPlusCampaignsLoading: isLoading,
    isStudioPlusCampaignsFetching: isFetching,
    isStudioPlusCampaignsSuccess: isSuccess,
    isStudioPlusCampaignsError: isError,
    studioPlusCampaigns,
  }
}

export function useSubscriptionDetails(accommodationId, productId, subscriptionNumber) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['subscriptionDetails', accommodationId, productId, subscriptionNumber],
    enabled: productId !== null,
    queryFn: ({ queryKey }) =>
      PaymentService.fetchSubscriptionDetails({
        variables: { accommodationId: queryKey[1], productId: queryKey[2], subscriptionNumber: queryKey[3] },
      }),
  })
  const subscriptionDetails = computed(() => data?.value?.data?.data?.subscription)
  return {
    subscriptionDetails,
    subscriptionDetailsError: isError,
    subscriptionDetailsLoading: isLoading,
    subscriptionDetailsFetching: isFetching,
  }
}

const getTransactionsHistory = async id => {
  const response = await PremiumService.fetchTransactionsHistory(id)
  return response?.data
}

export function useSubscriptionHistory(subscriptionNumber) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['subscriptionHistory', subscriptionNumber],
    queryFn: ({ queryKey }) => getTransactionsHistory(queryKey[1]),
  })

  const subscriptionHistory = computed(() => data?.value)
  return { subscriptionHistory, isError, isLoading, isFetching }
}

export function useAccommodationDetails(accommodationId, productName) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['accommodationDetails', accommodationId],
    enabled: productName.includes('Rate Connect'),
    queryFn: ({ queryKey }) =>
      PaymentService.fetchAccommodationDetails({
        variables: { accommodationId: queryKey[1] },
      }),
  })
  const accommodationDetails = computed(() => data?.value?.data?.data?.accommodationDetails)
  return { accommodationDetails, isError, isLoading, isFetching }
}

export function useSubscriptionCancelMutation() {
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: cancelSubscription,
  } = useMutation({
    mutationFn: ({ accommodationId, productId }) =>
      PaymentService.sendSubscriptionCancellation({
        variables: { input: { accommodation_id: accommodationId, product_id: productId } },
      }),
  })

  return {
    isLoading,
    isSuccess,
    isError,
    cancelSubscription,
  }
}
