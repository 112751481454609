<template>
  <div
    ref="root"
    class="tw-shadow-mdLg tw-bg-white tw-w-full tw-z-40 tw-fixed"
    :class="isMobileAppNavOpen ? 'tw-rounded-lg md:tw-rounded-none' : ''"
  >
    <div class="tw-grow">
      <div class="tw-flex tw-justify-between tw-relative">
        <div class="tw-flex tw-items-center">
          <Logo class="tw-ml-4 tw-mr-2 md:tw-mr-5" />
          <AccommodationSwitcher v-if="hasOrganisations" :is-mobile="isMobile" />
        </div>
        <div class="tw-flex tw-items-center">
          <NavSupportCenter v-if="(isMobile && isOnboarding) || !isMobile" :isMobile="isMobile" />
          <NavUserMenu v-if="!isOnboarding" />
          <button
            v-else
            id="signOutButton"
            class="hover:tw-bg-gray-200 sm:tw-px-4 sm:tw-py-2 sm:tw-mr-2 tw-ml-3 sm:tw-ml-0 tw-rounded-lg tw-p-2.5"
            @click="logout"
            @keydown.enter="logout"
          >
            <div class="tw-flex tw-items-center">
              <BaseIcon :height="24" :width="24" class="tw-fill-none tw-mr-2" icon-name="arrow-left">
                <ArrowLeft />
              </BaseIcon>
              <span v-if="!isMobile" class="tw-text-sm tw-text-gray-900">{{ $t('userMenu.signOut') }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isOnboarding && selectedAccommodationId" class="tw-border-t tw-border-gray-200">
      <div class="tw-grow md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-m-auto md:tw-px-4 tw-overflow-hidden">
        <NavBarNavigationLinks
          :is-mobile="isMobile"
          :isMobileAppNavOpen="isMobileAppNavOpen"
          @toggleMobileAppNav="toggleMobileAppNav"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'

import Logo from '@/components/dashboard/Logo.vue'
import NavUserMenu from '@/components/dashboard/nav/NavUserMenu.vue'
import AccommodationSwitcher from '@/components/dashboard/accommodation/AccommodationSwitcher.vue'
import NavBarNavigationLinks from '@/components/dashboard/nav/NavBarNavigationLinks.vue'
import NavSupportCenter from '@/components/dashboard/nav/NavSupportCenter.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const isMobile = document.body.clientWidth >= 768 ? ref(false) : ref(true)
const isMobileAppNavOpen = ref(false)
const isOnboardingUserDetails = computed(() => store.state.session.isOnboardingUserDetails)
const isOnboardingAssignments = computed(() => store.state.session.isOnboardingAssignments)
const organisations = computed(() => store.state.session.organisations)
const hasOrganisations = computed(() => Array.isArray(organisations.value) && organisations.value.length > 0)
const isOnboarding = computed(() => isOnboardingUserDetails.value || isOnboardingAssignments.value)

const toggleMobileAppNav = () => {
  isMobileAppNavOpen.value = !isMobileAppNavOpen.value
}

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = event => {
  isMobile.value = event.target.innerWidth >= 768 ? false : true
}

const logout = () => {
  store.dispatch('session/logout')
}
</script>
