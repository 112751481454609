import axios from 'axios'
import BaseService from './BaseService.js'

export const paymentApiUrl = `${import.meta.env.VITE_GATEWAY}/graphql`

class PaymentService extends BaseService {
  constructor() {
    super({
      baseUrl: paymentApiUrl,
    })
  }

  fetchBillingAccountDetails({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'account',
        variables,
        query: `query account($accountNumber: String!) {
          account (accountKey: $accountNumber) {
            basicInfo {
              id,
              name,
              accountNumber
            },
            billToContact {
              firstName,
              lastName,
              workEmail,
              address1,
              address2,
              city,
              state,
              country,
              zipCode
            },
            taxInfo {
              exemptCertificateId,
              exemptStatus,
              VATId
            },
            billingAndPayment {
              currency
            }
          }
        }`,
      },
    })
  }

  async fetchSubscriptionsByAccommodations() {
    return await axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'getSubscriptionsByAccommodation',
        query: `query getSubscriptionsByAccommodation {
          subscriptionsByAccommodations {
            accommodation {
              accommodation_id
              name
            }
            subscriptions {
              subscription_id
              subscription_number
              status
              term_end_date
              product {
                id
                details {
                  name
                }
              }
              pricing_plan {
                charges {
                  type
                }
                details {
                  name
                }
              }
            }
          }
        }
        `,
      },
    })
  }

  fetchSubscriptionDetails({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'getSubscriptionDetails',
        variables,
        query: `query getSubscriptionDetails(
          $productId: UUID!
          $accommodationId: Int!
          $subscriptionNumber: String
        ) {
          subscription(
            productId: $productId
            accommodationId: $accommodationId
            subscriptionNumber: $subscriptionNumber
          ) {
            subscription_number
            subscription_id
            status
            term_end_date
            quantity
            pricing_plan {
              id
              plan_type
              charges {
                id
                type
                unit
                billing_interval
                billing_interval_count
              }
              details {
                name
              }
            }
            product {
              type
              applications
              details {
                name
                icon
              }
            }
            renewal_preview {
              amount
              renewal_date
              currency
            }
          }
        }`,
      },
    })
  }

  fetchAccommodationDetails({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'getAccommodationDetails',
        variables,
        query: `query getAccommodationDetails($accommodationId: Int!) {
          accommodationDetails(accommodationId: $accommodationId) {
            name
          }
        }`,
      },
    })
  }

  fetchSubscriptionHistory({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'subscriptionHistory',
        variables,
        query: `query subscriptionHistory($subscriptionNumber: String!) {
          subscriptionHistory(subscriptionNumber: $subscriptionNumber) {
            ...SubscriptionInvoice
            ...SubscriptionVoucher
          }
        }

        fragment SubscriptionInvoice on SubscriptionInvoice {
          id
          posted_date
          file_id
          type
          __typename
        }

        fragment SubscriptionVoucher on SubscriptionVoucher {
          type
          redeemed_date
          amount
          currency
          __typename
        }`,
      },
    })
  }

  sendSubscriptionCancellation({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'cancelSubscription',
        variables,
        query: `mutation cancelSubscription($input: CancelSubscriptionInput!) {
          cancelSubscription(input: $input) {
            subscription_id
            subscription_number
            status
            term_end_date
          }
        }
        `,
      },
    })
  }

  fetchOwnedPricePlan({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'getOwnedPricePlanByProduct',
        variables,
        query: `query getOwnedPricePlanByProduct($input: OwnedPricePlanByProductInput!) {
          ownedPricePlanByProduct(input: $input) {
            id
          }
        }`,
      },
    })
  }

  fetchProductForDetailsPageQuery({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'getProductForDetailsPage',
        query: `query getProductForDetailsPage($product_id: UUID!, $languageCode: String!) {
          product(product_id: $product_id) {
            id
            type
            applications
            details(language_code: $languageCode) {
              id
              name
              description
              description_short
              description_title
              video_id
              icon
              highlights {
                title
                body
                image_url
              }
              screenshots {
                image_url
              }
            }
            pricing {
              id
              plan_type
              details(language_code: $languageCode) {
                name
                description
                features
                language_code
              }
              charges {
                id
                type
                unit
                price_schema
              }
            }
          }
        }`,
        variables,
      },
    })
  }

  trackOnMetricAggregator({ variables }) {
    return axios({
      url: this.baseUrl,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: {
        operationName: 'sendMetricLog',
        query: `mutation sendMetricLog($input: SendMetricLogInput!) {
          sendMetricLog(input: $input)
        }`,
        variables,
      },
    })
  }
}

export default new PaymentService()
