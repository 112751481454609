<template>
  <path
    d="M10.3633 4.45728L2.25653 18.517C2.08938 18.8177 2.00094 19.1587 2.00001 19.5059C1.99907 19.8531 2.08567 20.1946 2.25119 20.4963C2.41671 20.7979 2.65538 21.0493 2.94346 21.2254C3.23153 21.4015 3.55897 21.4962 3.8932 21.5H20.1068C20.441 21.4962 20.7685 21.4015 21.0565 21.2254C21.3446 21.0493 21.5833 20.7979 21.7488 20.4963C21.9143 20.1946 22.0009 19.8531 22 19.5059C21.9991 19.1587 21.9106 18.8177 21.7435 18.517L13.6367 4.45728C13.466 4.16505 13.2258 3.92345 12.9391 3.75577C12.6524 3.58809 12.329 3.5 12 3.5C11.671 3.5 11.3476 3.58809 11.0609 3.75577C10.7742 3.92345 10.534 4.16505 10.3633 4.45728Z"
    stroke="#171717"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11 17.5C11 16.948 11.448 16.5 12 16.5C12.552 16.5 13 16.948 13 17.5C13 18.052 12.552 18.5 12 18.5C11.448 18.5 11 18.052 11 17.5Z"
    fill="#171717"
  />
  <path d="M12 13.5V9.5" stroke="#171717" stroke-width="2" stroke-linecap="round" />
</template>
