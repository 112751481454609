<template>
  <div
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-header-and-footer tw-px-4"
  >
    <div
      class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-mb-8"
    >
      <AppMenu :data="tabs" :loading="false" />
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AppMenu from '@/components/appMenu/AppMenu.vue'

const store = useStore()
const router = useRouter()
const adminTabs = ref([
  {
    label: 'form.info.tab_team_settings',
    route: '/settings/team-settings',
    testId: 'settings-team-settings',
  },
  {
    label: 'form.info.tab_profile_settings',
    route: '/settings/profile-settings',
    testId: 'settings-profile-settings',
  },
  {
    label: 'paymentMethods.tabTitle',
    route: '/settings/payment-methods',
    testId: 'settings-payment-methods',
  },
  {
    label: 'subscriptions.tabTitle',
    route: '/settings/subscriptions',
    testId: 'settings-subscriptions',
  },
  {
    label: 'form.info.tab_language_currency',
    route: '/settings/locale-settings',
    testId: 'settings-locale-settings',
  },
])
const memberTabs = ref([
  {
    label: 'form.info.tab_profile_settings',
    route: '/settings/profile-settings',
  },
  {
    label: 'form.info.tab_language_currency',
    route: '/settings/locale-settings',
  },
])

const userRole = computed(() => store.state.session?.userRole)
const tabs = computed(() => (userRole.value === 'admin' ? adminTabs.value : memberTabs.value))
const adminRoutes = [
  'settings-team-settings',
  'settings-subscriptions',
  'settings-subscription-details',
  'settings-subscriptions-edit-payment',
  'settings-payment-methods',
]

onMounted(() => {
  if (userRole.value !== 'admin' && adminRoutes.includes(router.currentRoute.value.name)) {
    router.push('/settings/profile-settings')
  }
})

watch(userRole, role => {
  if (role !== 'admin' && adminRoutes.includes(router.currentRoute.value.name)) {
    router.push('/settings/profile-settings')
  }
})
</script>
