<template>
  <BaseCard v-if="isFetching" no-padding class="tw-animate-pulse tw-bg-gray-300 tw-w-full tw-h-16" />

  <component
    :is="notificationComponent"
    v-else-if="isSuccess"
    :severity="topPriorityNotification.severity"
    :extra-parameters="topPriorityNotification.extraParameters"
    :dismissible="topPriorityNotification.dismissible"
    :name="topPriorityNotification.name"
    :action="topPriorityNotification.action"
  />
</template>

<script setup>
import { computed } from 'vue'
import minBy from 'lodash/minBy'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import NotificationConnectivityIssue from './NotificationConnectivityIssue.vue'
import NotificationGoodBudget from './NotificationGoodBudget.vue'
import NotificationCampaignInactiveOrPaused from './NotificationCampaignInactiveOrPaused.vue'
import NotificationGoodCPA from './NotificationGoodCPA.vue'
import NotificationAMS from './NotificationAMS.vue'
import NotificationBudgetAlmostRunout from './NotificationBudgetAlmostRunout.vue'
import NotificationBudgetRunout from './NotificationBudgetRunout.vue'
import NotificationUnavailability from './NotificationUnavailability.vue'
import { useNotifications } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { isFetching, isSuccess, notificationsData } = useNotifications(selectedAccommodationId)

const topPriorityNotification = computed(() => minBy(notificationsData.value, 'priority'))
const notificationComponent = computed(() => {
  switch (topPriorityNotification.value?.name) {
    case 'notification_connectivity_issue':
      return NotificationConnectivityIssue
    case 'notification_good_budget':
      return NotificationGoodBudget
    case 'notification_campaign_inactive_or_paused':
      return NotificationCampaignInactiveOrPaused
    case 'notification_good_cpa':
      return NotificationGoodCPA
    case 'notification_ams_adoption':
      return NotificationAMS
    case 'notification_budget_almost_runout':
      return NotificationBudgetAlmostRunout
    case 'notification_budget_runout':
      return NotificationBudgetRunout
    case 'notification_unavailability':
      return NotificationUnavailability
    default:
      return null
  }
})
</script>
