<template>
  <BaseCard
    no-padding
    class="tw-max-w-[360px] tw-border-b tw-border-gray-300 tw-px-4 tw-pb-8 tw-pt-9 tw-flex-1 tw-relative tw-w-full tw-mx-auto md:tw-mx-0"
    :class="{ 'tw-h-fit': type === 'free' }"
  >
    <p class="tw-pb-4 tw-px-1 tw-font-bold tw-text-xl">
      {{ title }}
    </p>
    <div v-if="type !== 'free'">
      <div class="tw-mt-3 tw-flex tw-items-start tw-flex-col tw-min-h-[140px]">
        <div
          v-if="type === 'annual'"
          class="tw-py-1 tw-px-3 tw-bg-red-800 tw-font-bold tw-text-xs tw-text-white tw-rounded-tl-xl tw-rounded-br-xl"
        >
          <span>{{ $t('business_studio_plus_annual_switch_tag', { DiscountPercentage: '15' }) }}</span>
        </div>
        <div v-if="type === 'annual'">
          <p
            class="tw-font-light current-price"
            v-html="
              $t('business_studio_plus_price_discounted', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(annualPrice / 100, 'currencyCode')}`,
              })
            "
          />
          <p class="tw-font-light tw-line-through">
            {{
              $t('business_studio_plus_price_no_discount', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}`,
              })
            }}
          </p>
        </div>

        <div v-if="type === 'monthly'">
          <p class="tw-font-light current-price">
            <i18n-t keypath="business_studio_plus_price_monthly" tag="p" scope="global">
              <template #MonthlyPrice>
                <span>{{ `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}` }}</span>
              </template>
            </i18n-t>
          </p>
        </div>
        <div class="tw-text-gray-500 tw-font-light tw-text-sm tw-flex">
          <span>{{ $t('business_studio_plus_tax_disclaimer') }}</span>
          <div class="divider" />
          <span>{{
            type === 'annual' ? $t('business_studio_plus_annual_billing') : $t('business_studio_plus_monthly_billing')
          }}</span>
        </div>
      </div>
      <MainButton @click="select(type)" :disabled="!isAdmin" class="tw-w-full tw-mt-4"
        ><span>{{
          type === 'annual' ? $t('subscription_annual_cta') : $t('subscription_monthly_cta')
        }}</span></MainButton
      >
      <div class="tw-border-t tw-border-b-gray-300 tw-mt-6">
        <ul class="tw-flex tw-flex-col tw-gap-2 tw-mt-5 tw-px-1">
          <li class="tw-flex tw-justify-between tw-items-center">
            <span>{{ $t('business_studio_plus_basic_features_comparison') }}</span>
            <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-800 tw-p-[4px] tw-rounded-full tw-fill-white" />
          </li>
          <p class="tw-font-bold tw-mt-4 tw-mb-3">{{ planName }}</p>
          <li v-for="(plusFeature, i) in featuresList" :key="i" class="tw-flex tw-justify-between tw-items-center">
            <span class="tw-mr-6">{{ $t(plusFeature) }}</span>
            <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-800 tw-p-[4px] tw-rounded-full tw-fill-white" />
          </li>
        </ul>
      </div>
    </div>
    <div v-if="type === 'free'">
      <ul class="tw-flex tw-flex-col tw-gap-2 tw-px-1">
        <p class="tw-font-bold tw-mt-4 tw-mb-3">{{ planName }}</p>
        <li v-for="(feature, i) in featuresList" :key="i" class="tw-flex tw-justify-between tw-items-center">
          <span class="tw-mr-6">{{ $t(feature) }}</span>
          <CheckMark class="tw-h-[20px] tw-w-[20px] tw-bg-green-800 tw-p-[4px] tw-rounded-full tw-fill-white" />
        </li>
      </ul>
    </div>
  </BaseCard>
</template>
<script setup>
import { CURRENCIES } from '@/constants/currencies.js'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import CheckMark from '@/components/icons/CheckMark.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

defineProps({
  type: String,
  featuresList: Array,
  title: String,
  planName: String,
  annualPrice: Number,
  monthlyPrice: Number,
  currency: String,
  isAdmin: Boolean,
})

const emit = defineEmits(['select'])
const select = type => emit('select', type)
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}

.divider:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: theme('colors.gray.500');
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 4px;
}
</style>
