<template>
  <div class="tw-mt-6" v-if="isPositive">
    <p
      v-if="type === 'MONTHLY'"
      v-html="
        $t('subscription_reactivate_success_text_1_monthly', {
          MonthlyPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price)} `,
          PaymentDate: nextPayment,
        })
      "
    />
    <p
      v-if="type !== 'MONTHLY'"
      v-html="
        $t('subscription_reactivate_success_text_2_annual', {
          MonthlyPrice: `${Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          }).format(price)}`,
          PaymentDate: nextPayment,
        })
      "
    />
    <p class="tw-mt-6" v-html="$t('subscription_reactivate_success_text_2')" />
  </div>

  <div class="tw-mt-6" v-if="!isPositive && retries < 3">
    <p class="tw-mb-6" v-html="$t('reactivation_error_message')" />
    <p class="tw-mb-3">{{ $t('reactivation_fail_tip_header') }}</p>
    <ul class="tw-ml-6">
      <li
        class="tw-list-disc tw-py-1 first:tw-pt-0 last:tw-pb-0 tw-leading-5"
        v-for="(item, index) in solutions"
        :key="index"
      >
        {{ $t(item) }}
      </li>
    </ul>
    <p class="tw-mt-6" v-html="$t('reactivation_fail_help')" />
  </div>
  <div class="tw-mt-6" v-if="!isPositive && retries >= 3">
    <p v-html="$t('reactivation_error_message_II')" />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  isPositive: Boolean,
  price: String,
  type: String,
  nextPaymentDate: String,
  retries: Number,
})

const solutions = ref(['reactivation_fail_tip_1', 'reactivation_fail_tip_2', 'reactivation_fail_tip_3'])

const nextPayment = computed(() => {
  return new Date(props.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale.language)
</script>
<style scoped>
p :deep(a) {
  text-decoration: underline;
}
</style>
